/**
 * @module react/components/flightpath/flightpath-list
 */
import React from 'react';

import FlightpathWaypoint from './flightpath-waypoint';

/**
 *
 */
export default function FlightpathList(props) {
  return (
    <ul>
      {props.waypoints.map((waypoint) => (
        <FlightpathWaypoint
          key={waypoint.id}
          waypoint={waypoint}
          editWaypoint={props.editWaypoint}
        />
      ))}
    </ul>
  );
}
