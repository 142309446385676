/**
 * @module redux/actions/icing
 */

export const ICING_RESET = 'ICING_RESET';
export const ICING_XHR_DISPATCH = 'ICING_XHR_DISPATCH';
export const ICING_XHR_CANCEL = 'ICING_XHR_CANCEL';
export const ICING_XHR_ERROR = 'ICING_XHR_ERROR';
export const ICING_XHR_SUCCESS = 'ICING_XHR_SUCCESS';

/**
 * Restores the initial state.
 * @returns {FluxStandardAction}
 */
export function resetIcing() {
  return { type: ICING_RESET };
}

/**
 * @param {number} init
 * @param {number} time
 * @param {object[]} latlngs
 * @returns {FluxStandardAction}
 */
export function dispatchIcingRequest(init, time, latlngs) {
  return {
    type: ICING_XHR_DISPATCH,
    payload: { init, time, latlngs },
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function cancelIcingRequest(error) {
  return {
    type: ICING_XHR_CANCEL,
    payload: error,
    error: true,
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function icingRequestFailed(error) {
  return {
    type: ICING_XHR_ERROR,
    payload: error,
    error: true,
  };
}

/**
 * @param {object} response
 * @returns {FluxStandardAction}
 */
export function icingRequestSuccessful(response) {
  return {
    type: ICING_XHR_SUCCESS,
    payload: response,
  };
}
