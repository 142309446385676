/**
 * @module react/containers/app
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.scss';

import Crosssection from './components/crosssection';
import Forecasts from './components/forecasts';
import Widget from './components/widget';
import Flightpath from './components/flightpath';
import Modal from './components/modal';

import {
  issuePresetsRequest,
  cancelPresetsRequest,
} from './redux/actions/presets';

/**
 *
 */
class App extends Component {
  /**
   *
   */
  componentDidMount() {
    this.props.issueFetch();
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.cancelFetch();
  }

  /**
   *
   */
  render() {
    return (
      <div className="wrapper">
        <ul className="regions">
          <li className="region region--crosssection">
            <Crosssection />
          </li>

          <li className="region region--forecasts">
            <Forecasts />
          </li>

          <li className="region region--map">
            <Widget />
          </li>

          <li className="region region--waypoints">
            <Flightpath />
          </li>
        </ul>

        <div className="fatalerror">
          <div className="fatalerror-content">
            <p>
              <strong>The viewport is too small for the application.</strong>
            </p>
            <p>You need at least 768x600 to see the application.</p>
          </div>
        </div>

        <Modal />
      </div>
    );
  }
}

/**
 *
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    issueFetch: () => dispatch(issuePresetsRequest()),
    cancelFetch: () => dispatch(cancelPresetsRequest()),
  };
}

export default connect(null, mapDispatchToProps)(App);
