/**
 * @module react/containers/modal
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import FormItem from './form-item';
import FormTextinput from './form-textinput';
import FormSelect from './form-select';

import {
  saveWaypointForm,
  deleteWaypoint,
  hideWaypointForm,
} from '../../redux/actions/flightpath';

/**
 * @param {string} name
 * @param {any} value
 * @param {boolean} touched
 */
function setValue(name, value, touched = true) {
  value = '' + (value || '');

  let required = (options[name] || {}).required || false;
  let pattern = (options[name] || {}).pattern || null;

  let empty = value.length === 0;
  let valid = true;
  let error = null;

  if (required && empty) {
    error = '' + (options[name] || {}).valueMissing;
    valid = false;
  } else if (pattern && !pattern.test(value)) {
    error = '' + (options[name] || {}).patternMismatch;
    valid = false;
  }

  return { value, empty, touched, valid, error };
}

let options = {
  lat: {
    required: true,
    pattern: /^-?\d{1,2}\.\d+$/,
    valueMissing: 'Please provide a latitude.',
    patternMismatch: 'The provided latitude is invalid.',
  },
  lng: {
    required: true,
    pattern: /^-?\d{1,3}\.\d+$/,
    valueMissing: 'Please provide a longitude.',
    patternMismatch: 'The provided longitude is invalid.',
  },
};

let field_initial = {
  value: '',
  empty: true,
  touched: false,
  valid: false,
  error: null,
};

let preset_initial = {
  value: '',
  empty: true,
  touched: false,
  valid: true,
  error: null,
};

let state_initial = {
  form: {
    valid: false,
    touched: false,
  },
  fields: {
    lat: field_initial,
    lng: field_initial,
    base: preset_initial,
    hospital: preset_initial,
    route: preset_initial,
  },
};

/**
 * @param {array} presets
 */
function preparePresets(presets) {
  let all = presets.map((preset, index) => ({
    type: preset.type,
    label: preset.name,
    value: preset.id,
  }));

  let bases = all.filter(
    (preset) => ['military', 'heliport', 'rega'].indexOf(preset.type) !== -1
  );
  let hospitals = all.filter((preset) => preset.type === 'hospital');
  let routes = all.filter((preset) => preset.type === 'route');

  return { bases, hospitals, routes };
}

/**
 *
 */
class WaypointForm extends Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.onKeyUpHandler = this.onKeyUp.bind(this);
    this.onChangeHandler = this.onChange.bind(this);
    this.onSubmitHandler = this.onSubmit.bind(this);
    this.onDeleteHandler = this.onDelete.bind(this);
    this.onCloseHandler = this.onClose.bind(this);

    this.presets = preparePresets(this.props.presets);

    this.state = state_initial;
  }

  /**
   *
   */
  checkForm() {
    let form = {
      valid: true,
      touched: false,
    };

    Object.keys(this.state.fields).forEach((name) => {
      let field = this.state.fields[name];
      form.valid = form.valid && field.valid;
      form.touched = field.touched || form.touched;
    });

    this.setState({ form });
  }

  /**
   *
   */
  onChange(event) {
    let name = event.target.name;
    let value = '' + event.target.value;

    let fields = cloneDeep(this.state.fields);

    fields.base = preset_initial;
    fields.hospital = preset_initial;
    fields.route = preset_initial;

    fields[name] = setValue(name, value);

    if (['base', 'hospital', 'route'].indexOf(name) !== -1) {
      if (value === '') {
        fields.lat = setValue('lat', '');
        fields.lng = setValue('lng', '');
      } else {
        let preset = find(this.props.presets, ['id', +value]);

        fields.lat = setValue('lat', preset.lat, false);
        fields.lng = setValue('lng', preset.lng, false);
      }
    }

    this.setState({ fields });

    this.checkForm();
  }

  /**
   *
   */
  getWaypointFromState() {
    return {
      id: this.props.waypoint.id,
      index: this.props.waypoint.index,

      lat: this.state.fields.lat.value,
      lng: this.state.fields.lng.value,

      base: this.state.fields.base.value,
      hospital: this.state.fields.hospital.value,
      route: this.state.fields.route.value,
    };
  }

  /**
   *
   */
  onSubmit(event) {
    event.preventDefault();

    this.checkForm();

    if (this.state.form.valid) {
      this.props.saveWaypoint(this.getWaypointFromState());
    } else {
      let fields = cloneDeep(this.state.fields);
      fields.lat.touched = true;
      fields.lng.touched = true;

      this.setState({ fields });
    }
  }

  /**
   *
   */
  onClose(event) {
    event.preventDefault();

    this.props.hideForm();

    this.setState(state_initial);
  }

  /**
   *
   */
  onDelete(event) {
    this.props.deleteWaypoint(this.getWaypointFromState());
  }

  /**
   *
   */
  onKeyUp(event) {
    if (this.props.waypoint === null) {
      return;
    }

    event.stopPropagation();

    switch (event.key) {
      case 'Escape':
        this.onClose(event);
        break;

      case 'Enter':
        this.onSubmit(event);
        break;

      default:
        break;
    }
  }

  //  ____                 _
  // |  _ \ ___  __ _  ___| |_
  // | |_) / _ \/ _` |/ __| __|
  // |  _ <  __/ (_| | (__| |_
  // |_| \_\___|\__,_|\___|\__|
  //

  /**
   *
   */
  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUpHandler);
  }

  /**
   *
   */
  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUpHandler);
  }

  /**
   *
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    let mapping = {
      military: 'base',
      heliport: 'base',
      rega: 'base',
      hospital: 'hospital',
      route: 'route',
    };

    if (nextProps.waypoint !== null) {
      let fields = cloneDeep(this.state.fields);

      if (nextProps.waypoint.preset) {
        let preset = find(nextProps.presets, [
          'id',
          +nextProps.waypoint.preset,
        ]);
        let name = mapping[preset.type];

        fields[name] = setValue(name, preset.id, false);

        fields.lat = setValue('lat', preset.lat, false);
        fields.lng = setValue('lng', preset.lng, false);
      } else {
        fields.lat = setValue('lat', nextProps.waypoint.lat, false);
        fields.lng = setValue('lng', nextProps.waypoint.lng, false);
      }

      this.setState({ fields });
    } else {
      this.setState(state_initial);
    }

    this.checkForm();
  }

  /**
   *
   */
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!isEqual(nextProps.presets, this.props.presets)) {
      this.presets = preparePresets(nextProps.presets);
    }
  }

  /**
   *
   */
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.fields, this.state.fields)) {
      this.checkForm();
    }
  }

  /**
   *
   */
  render() {
    if (this.props.waypoint === null) {
      return null;
    }

    return (
      <div className="modal" onKeyUp={this.onKeyUpHandler}>
        <div className="modal-dialog">
          <form className="form" onSubmit={this.onSubmitHandler}>
            <div className="modal-header">
              <button
                className="modal-close"
                type="button"
                onClick={this.onCloseHandler}
              >
                Close
              </button>

              <h1 className="modal-headline">
                {this.props.waypoint.id !== undefined
                  ? 'Edit Waypoint ' + (this.props.waypoint.index + 1)
                  : 'Add new Waypoint'}
              </h1>
            </div>

            <div className="modal-body">
              <FormItem
                label="Latitude (N)"
                touched={this.state.fields.lat.touched}
                valid={this.state.fields.lat.valid}
                error={this.state.fields.lat.error}
              >
                <FormTextinput
                  name="lat"
                  value={this.state.fields.lat.value}
                  onChange={this.onChangeHandler}
                />
              </FormItem>

              <FormItem
                label="Longitude (E)"
                touched={this.state.fields.lng.touched}
                valid={this.state.fields.lng.valid}
                error={this.state.fields.lng.error}
              >
                <FormTextinput
                  name="lng"
                  value={this.state.fields.lng.value}
                  onChange={this.onChangeHandler}
                />
              </FormItem>

              <FormItem
                label="Bases"
                touched={this.state.fields.base.touched}
                valid={this.state.fields.base.valid}
                error={this.state.fields.base.error}
              >
                <FormSelect
                  name="base"
                  placeholder="Select an airfield"
                  value={this.state.fields.base.value}
                  options={this.presets.bases}
                  onChange={this.onChangeHandler}
                />
              </FormItem>

              <FormItem
                label="Hospitals"
                touched={this.state.fields.hospital.touched}
                valid={this.state.fields.hospital.valid}
                error={this.state.fields.hospital.error}
              >
                <FormSelect
                  name="hospital"
                  placeholder="Select a hospital"
                  value={this.state.fields.hospital.value}
                  options={this.presets.hospitals}
                  onChange={this.onChangeHandler}
                />
              </FormItem>

              <FormItem
                label="Air-Traffic Routes"
                touched={this.state.fields.route.touched}
                valid={this.state.fields.route.valid}
                error={this.state.fields.route.error}
              >
                <FormSelect
                  name="route"
                  placeholder="Select an air-traffic route"
                  value={this.state.fields.route.value}
                  options={this.presets.routes}
                  onChange={this.onChangeHandler}
                />
              </FormItem>
            </div>

            <div className="modal-footer">
              {this.props.waypoint.id && (
                <button
                  className="modal-footer-delete"
                  type="button"
                  onClick={this.onDeleteHandler}
                >
                  Del
                </button>
              )}

              <button
                className="modal-footer-save"
                type="submit"
                disabled={!this.state.form.valid}
              >
                {this.props.waypoint.id ? 'Save' : 'Add'}
              </button>

              <input
                type="hidden"
                name="id"
                value={this.props.waypoint.id || ''}
              />
              <input
                type="hidden"
                name="index"
                value={this.props.waypoint.index || 0}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

/**
 *
 */
function mapStateToProps(state, ownProps) {
  return {
    waypoint: state.flightpath.edit,
    presets: state.presets.waypoints,
  };
}

/**
 *
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    hideForm: () => dispatch(hideWaypointForm()),
    deleteWaypoint: (waypoint) => dispatch(deleteWaypoint(waypoint)),
    saveWaypoint: (data) => dispatch(saveWaypointForm(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WaypointForm);
