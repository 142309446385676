/**
 * @module redux/actions/widget
 */
export const WIDGET_SYNC_STARTSTOPTIME = 'WIDGET_SYNC_STARTSTOPTIME';
export const WIDGET_SYNC_CURRENTTIME = 'WIDGET_SYNC_CURRENTTIME';
export const WIDGET_SYNC_NOWTIME = 'WIDGET_SYNC_NOWTIME';
export const WIDGET_SYNC_ALTITUDE = 'WIDGET_SYNC_ALTITUDE';
export const WIDGET_SYNC_VIEW = 'WIDGET_SYNC_VIEW';

/**
 * @param {number} start
 * @param {number} stop
 * @returns {FluxStandardAction}
 */
export function updateStartStopTime(start, stop) {
  return {
    type: WIDGET_SYNC_STARTSTOPTIME,
    payload: { start, stop },
  };
}

/**
 * @param {number} current
 * @returns {FluxStandardAction}
 */
export function updateCurrentTime(current) {
  return {
    type: WIDGET_SYNC_CURRENTTIME,
    payload: { current },
  };
}

/**
 * @param {number} now
 * @returns {FluxStandardAction}
 */
export function updateNowTime(now) {
  return {
    type: WIDGET_SYNC_NOWTIME,
    payload: { now },
  };
}

/**
 * @param {number} altitude
 * @returns {FluxStandardAction}
 */
export function updateAltitude(altitude) {
  return {
    type: WIDGET_SYNC_ALTITUDE,
    payload: { altitude },
  };
}

/**
 * @param {number[]} center
 * @param {number} zoom
 * @returns {FluxStandardAction}
 */
export function updateView(center, zoom) {
  return {
    type: WIDGET_SYNC_VIEW,
    payload: { center, zoom },
  };
}
