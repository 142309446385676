/**
 * @module react/components/modal/form-select
 */
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

/*
 {
     id: Number,
     name: String,
     value: String,
     disabled: Boolean,
     size: Number,
     onChange: Function,
     options: [
         {
             name: String,
             value: String,
             label: String,
             disabled: Boolean,
         },
     ],
 }
 */

/**
 *
 */
export default function FormSelect(props) {
  return (
    <select
      className="form-select"
      id={'form-' + props.name}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      multiple={props.size > 1}
      size={props.size || 1}
      onChange={props.onChange}
    >
      <option key="0" value="">
        {props.placeholder}
      </option>

      {props.options.map((option, index) => (
        <option
          key={index + 1 + ''}
          value={option.value}
          disabled={option.disabled || ''}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
}

/**
 *
 */
FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

/**
 *
 */
FormSelect.defaultProps = {
  disabled: false,
  size: 1,
  options: [],
  onChange: noop,
};
