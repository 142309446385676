/**
 * @module react/components/crosssection/crosssection-canvas
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { line as d3Line } from 'd3-shape';
import { rgb } from 'd3-color';
import { max, range, zip } from 'd3-array';
import { scaleLinear, scaleSequential } from 'd3-scale';
import { isoBands, isoContours } from 'marchingsquares';
import queryString from 'query-string';
import L from 'leaflet';

import { updateAltitude } from '../../redux/actions/widget';

import { ALTITUDES, ICING_LEVELS } from '../../config/config-base';
import { metersToFeet } from '../../utility/convert';

const ALTITUDE_BUFFER = 500;

/**
 * Creates a new canvas element with the width and height from the source element and returns its rendering context.
 * @param {HTMLCanvasElement} source
 * @returns {CanvasRenderingContext2D}
 */
function createContext(source) {
  let { width, height } = source;

  let canvas = document.createElement('canvas');
  Object.assign(canvas, { width, height });

  let context = canvas.getContext('2d');

  return context;
}

/**
 * Sets the line dash pattern if supported by the browser.
 * @param {CanvasRenderingContext2D} context
 * @param {array} segments
 * @return {void}
 */
function setLineDash(context, segments) {
  if (typeof context.setLineDash === 'function') {
    context.setLineDash(segments);
  }
}

/**
 * @see {@link https://stackoverflow.com/a/39838921}
 * @param {array} array
 * @param {number} width
 * @returns {array}
 */
function convertListToMatrix(array, width) {
  return array.reduce(function (rows, key, index) {
    if (index % width === 0) {
      rows.push([key]);
    } else {
      rows[rows.length - 1].push(key);
    }

    return rows;
  }, []);
}

/**
 * @param {array} data
 * @param {number} index
 * @returns {number}
 */
function interpolateIndex(data, index) {
  let interpolated;

  if (index % 1 === 0) {
    interpolated = data[index];
  } else {
    let ceiled = Math.ceil(index);
    let floored = Math.floor(index);
    let remainder_fraction = index % 1;
    let remainder_product = (data[ceiled] - data[floored]) * remainder_fraction;

    interpolated = data[floored] + remainder_product;
  }

  return interpolated;
}

/**
 *
 */
class CrosssectionCanvas extends Component {
  /**
   * @see {@link https://reactjs.org/docs/react-component.html#constructor}
   * @param {object} props Properties from mapStateToProps.
   */

  /**
   * Clears the main canvas by painting it over with the background color.
   * @return {void}
   */
  clearCanvas() {
    this.context.fillStyle = '#1c251c';
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  /**
   * Draws the topography if necessary and paints it onto the main canvas.
   * @return {void}
   */
  drawTopography() {
    if (this.props.topography.distances.length < 2) {
      return;
    }

    if (!this.topography) {
      let data = this.props.topography;
      let data_matrix = zip(data.distances, data.altitudes);

      let context = createContext(this.canvas);
      let canvas = context.canvas;
      let { width, height } = canvas;

      let scaleX = scaleLinear()
        .range([0, width])
        .domain([0, max(data.distances)]);

      let scaleY = scaleLinear()
        .range([0, height])
        .domain([0, max(ALTITUDES) + ALTITUDE_BUFFER]);

      let line = d3Line()
        .x((d) => scaleX(d[0]))
        .y((d) => height - scaleY(d[1]))
        .context(context);

      context.fillStyle = '#fff';

      context.beginPath();
      line(data_matrix);
      context.lineTo(width, height);
      context.lineTo(0, height);
      context.closePath();
      context.fill();

      this.topography = context;
    }

    this.context.drawImage(this.topography.canvas, 0, 0);
  }

  /**
   * Draws the icing if necessary and paints it onto the main canvas.
   * @return {void}
   */
  drawIcing() {
    let data = this.props.icing;
    let index = this.props.forecastRun + '.' + this.props.currentTime;

    if (!data.distances || data.distances.length < 2) {
      return;
    }

    if (!data.scalarfields[index]) {
      return;
    }

    if (!(this.icing && this.icing[index])) {
      let context = createContext(this.canvas);
      let canvas = context.canvas;
      let { width, height } = canvas;

      let scalarfield = data.scalarfields[index];

      let thresholds = [0, 1, 2, 3];

      let colors = ICING_LEVELS.map((level) => level.color);
      colors.unshift('transparent');

      let data_matrix = convertListToMatrix(scalarfield, data.distances.length);

      let x_domain = [0, data.total_distance];
      let x_range = [0, width];
      let interpolateX = interpolateIndex.bind(null, data.distances);
      let scaleXFromIndexToMeters = scaleSequential(interpolateX);
      let scaleXFromMetersToWidth = scaleLinear()
        .domain(x_domain)
        .range(x_range);
      let scaleX = (value) =>
        scaleXFromMetersToWidth(scaleXFromIndexToMeters(value));

      // Add 100 meters at the top of the crosssection.
      let y_domain = [0, max(data.altitudes) + ALTITUDE_BUFFER];
      let y_range = [height, 0];
      let interpolateY = interpolateIndex.bind(null, data.altitudes);
      let scaleYFromIndexToMeters = scaleSequential(interpolateY);
      let scaleYFromMetersToHeight = scaleLinear()
        .domain(y_domain)
        .range(y_range);
      let scaleY = (value) =>
        scaleYFromMetersToHeight(scaleYFromIndexToMeters(value));

      let line = d3Line()
        .x((data) => 0.5 + scaleX(data[0]))
        .y((data) => 0.5 + scaleY(data[1]))
        .context(context);

      let bands = [];
      for (let level = 1; level < thresholds.length; level++) {
        let band_lower = thresholds[level - 1];
        let band_upper = thresholds[level];
        let band_width = band_upper - band_lower;

        let band = isoBands(data_matrix, band_lower + 0.5, band_width + 0.5);

        bands.push({ paths: band, value: band_upper });
      }

      bands.forEach((band) => {
        band.paths.forEach((points) => {
          context.lineWidth = 0;
          context.fillStyle = colors[band.value];

          context.beginPath();
          line(points);
          context.fill();
        });
      });

      this.icing = this.icing || {};
      this.icing[index] = context;
    }

    this.context.drawImage(this.icing[index].canvas, 0, 0);
  }

  /**
   * Draws the clouds if necessary and paints onto the main canvas.
   * @return {void}
   */
  drawCloud() {
    let data = this.props.cloud;
    let index = this.props.forecastRun + '.' + this.props.currentTime;

    if (!data.distances || data.distances.length < 2) {
      return;
    }

    if (!data.scalarfields[index]) {
      return;
    }

    if (!(this.cloud && this.cloud[index])) {
      let context = createContext(this.canvas);
      let canvas = context.canvas;
      let { width, height } = canvas;

      let scalarfield = data.scalarfields[index];

      let data_matrix = convertListToMatrix(scalarfield, data.distances.length);

      let x_domain = [0, data.total_distance];
      let x_range = [0, width];
      let interpolateX = interpolateIndex.bind(null, data.distances);
      let scaleXFromIndexToMeters = scaleSequential(interpolateX);
      let scaleXFromMetersToWidth = scaleLinear()
        .domain(x_domain)
        .range(x_range);
      let scaleX = (value) =>
        scaleXFromMetersToWidth(scaleXFromIndexToMeters(value));

      // Add 100 meters at the top of the crosssection.
      let y_domain = [0, max(data.altitudes) + ALTITUDE_BUFFER];
      let y_range = [height, 0];
      let interpolateY = interpolateIndex.bind(null, data.altitudes);
      let scaleYFromIndexToMeters = scaleSequential(interpolateY);
      let scaleYFromMetersToHeight = scaleLinear()
        .domain(y_domain)
        .range(y_range);
      let scaleY = (value) =>
        scaleYFromMetersToHeight(scaleYFromIndexToMeters(value));

      let line = d3Line()
        .x((data) => 0.5 + scaleX(data[0]))
        .y((data) => 0.5 + scaleY(data[1]))
        .context(context);

      let bands = [];
      for (let level = 1; level < 8; level++) {
        let band = isoBands(data_matrix, level, 1);
        bands.push({ paths: band, value: level });
      }

      bands.forEach((band) => {
        band.paths.forEach((points) => {
          context.lineWidth = 0;
          context.fillStyle = `rgba(200, 200, 200, ${band.value / 10})`;

          context.beginPath();
          line(points);
          context.fill();
        });
      });

      this.cloud = this.cloud || {};
      this.cloud[index] = context;
    }

    this.context.drawImage(this.cloud[index].canvas, 0, 0);
  }

  /**
   * Draws the isotherms when available and paints them onto the main canvas.
   * @return {void}
   */
  drawIsotherms() {
    let data = this.props.isotherms;
    let index = this.props.forecastRun + '.' + this.props.currentTime;

    if (!data.distances || data.distances.length < 2) {
      return;
    }

    if (!data.scalarfields[index]) {
      return;
    }

    if (!(this.isotherms && this.isotherms[index])) {
      let context = createContext(this.canvas);
      let canvas = context.canvas;
      let { width, height } = canvas;

      let scalarfield = data.scalarfields[index];

      let data_matrix = convertListToMatrix(scalarfield, data.distances.length);

      let x_domain = [0, data.total_distance];
      let x_range = [0, width];
      let interpolateX = interpolateIndex.bind(null, data.distances);
      let scaleXFromIndexToMeters = scaleSequential(interpolateX);
      let scaleXFromMetersToWidth = scaleLinear()
        .domain(x_domain)
        .range(x_range);
      let scaleX = (value) =>
        scaleXFromMetersToWidth(scaleXFromIndexToMeters(value));

      // Add 100 meters at the top of the crosssection.
      let y_domain = [0, max(data.altitudes) + ALTITUDE_BUFFER];
      let y_range = [height, 0];
      let interpolateY = interpolateIndex.bind(null, data.altitudes);
      let scaleYFromIndexToMeters = scaleSequential(interpolateY);
      let scaleYFromMetersToHeight = scaleLinear()
        .domain(y_domain)
        .range(y_range);
      let scaleY = (value) =>
        scaleYFromMetersToHeight(scaleYFromIndexToMeters(value));

      let thresholds = range(-50, 50, 2);
      let query = queryString.parse(window.location.search);
      if (query.iso) {
        let [range_start, range_stop, range_step] = query.iso.split(',');

        if (!isNaN(range_start) && !isNaN(range_stop) && !isNaN(range_step)) {
          thresholds = range(range_start, range_stop, range_step);
        }
      }

      let bands = [];
      for (let level = 0; level < thresholds.length; level++) {
        let value = thresholds[level];
        let paths = isoContours(data_matrix, value);
        bands.push({ paths, value });
      }

      let color = rgb(150, 150, 150, 1);

      let line = d3Line()
        .x((data) => 0.5 + scaleX(data[0]))
        .y((data) => 0.5 + scaleY(data[1]))
        .context(context);

      // Path
      bands.forEach((band) => {
        band.paths.forEach((points) => {
          context.strokeStyle = color;
          context.lineWidth = band.value === 0 ? 3 : 1;
          context.lineJoin = 'round';

          context.beginPath();
          line(points);
          context.stroke();
        });
      });

      // Text
      bands.forEach((band) => {
        band.paths.forEach((points) => {
          context.textAlign = 'left';
          context.textBaseline = 'top';
          let char_width = context.measureText('M').width;
          let char_count = Math.max(2, band.value.toString().length);

          let width = char_width * char_count;
          let height = 12;

          let i = Math.round((points.length - 1) * Math.random());
          let point = points[i];

          let x = scaleX(point[0]);
          let y = scaleY(point[1]) - height / 2;

          context.clearRect(x, y, width, height);

          context.fillStyle = color;
          context.fillText(band.value, x + char_width / 2, y);
        });
      });

      this.isotherms = this.isotherms || {};
      this.isotherms[index] = context;
    }

    this.context.drawImage(this.isotherms[index].canvas, 0, 0);
  }

  /**
   * Draws the waypoints if necessary and paints them onto the main canvas.
   * @return {void}
   */
  drawWaypoints() {
    if (this.props.flightpath.length < 2) {
      return;
    }

    if (!this.waypoints) {
      let context = createContext(this.canvas);
      let { width, height } = context.canvas;

      let latlngs = this.props.flightpath.map((waypoint) =>
        L.latLng(waypoint.lat, waypoint.lng)
      );

      let distances = [];
      let distance_current;
      let distance_total = 0;

      for (let i = 1; i < latlngs.length; i++) {
        distance_current = latlngs[i - 1].distanceTo(latlngs[i]);
        distance_total += distance_current;

        distances.push(distance_total);
      }
      distances.pop();

      let scaleX = scaleLinear().range([0, width]).domain([0, distance_total]);

      let color = rgb(0, 255, 0, 1);

      setLineDash(context, [4, 2]);
      context.strokeStyle = color;
      context.fillStyle = color;
      context.lineWidth = 1;
      context.font = `15px glass_gaugeregular, sans-serif`;
      context.textAlign = 'left';
      context.textBaseline = 'middle';

      distances.forEach((distance, index) => {
        context.beginPath();
        context.moveTo(scaleX(distance), 0);
        context.lineTo(scaleX(distance), height);
        context.stroke();

        context.fillText(index + 2, scaleX(distance) + 5, 10);
      });

      // draw first point and last point numbers
      context.fillText(1, 5, 10);
      context.fillText(
        latlngs.length,
        latlngs.length.toString().length > 1 ? width - 17 : width - 10,
        10
      );

      setLineDash(context, []);

      this.waypoints = context;
    }

    this.context.drawImage(this.waypoints.canvas, 0, 0);
  }

  /**
   * Adjusts the horizontal line with the altitude.
   * @return {void}
   */
  drawHorizontal() {
    let altitude = Math.round(metersToFeet(this.props.horizontal) / 10) * 10;
    this.altitudeLine.style.bottom =
      this.scaleYMeters(this.props.horizontal) + 'px';
    this.altitudeLine.innerHTML = `${altitude} ft / ${this.props.horizontal} m / AMSL`;
  }

  /**
   * Draws all the layers onto the main canvas in the right order.
   * @return {void}
   */
  draw() {
    this.clearCanvas();
    this.drawTopography();
    this.drawCloud();
    this.drawIcing();
    this.drawIsotherms();
    this.drawWaypoints();
  }

  /**
   * @param {MouseEvent} event
   * @return {void}
   */
  handleClick(event) {
    let position = L.DomEvent.getMousePosition(event, this.canvas);
    let altitude_max = max(ALTITUDES) + ALTITUDE_BUFFER;
    let altitude =
      altitude_max - (altitude_max / this.canvas.height) * position.y;

    // https://stackoverflow.com/a/33179283
    altitude = Array.from(ALTITUDES).sort(
      (a, b) => Math.abs(altitude - a) - Math.abs(altitude - b)
    )[0];

    this.props.updateAltitude(altitude);
  }

  //  ____                 _
  // |  _ \ ___  __ _  ___| |_
  // | |_) / _ \/ _` |/ __| __|
  // |  _ <  __/ (_| | (__| |_
  // |_| \_\___|\__,_|\___|\__|
  //

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidmount}
   * @return {void}
   */
  componentDidMount() {
    // https://stackoverflow.com/a/10215724
    this.canvas.style.width = '100%';
    this.canvas.style.height = '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    this.context = this.canvas.getContext('2d');

    this.scaleYMeters = scaleLinear()
      .range([0, this.canvas.height])
      .domain([0, max(ALTITUDES) + ALTITUDE_BUFFER]);

    this.drawHorizontal();
    this.draw();

    L.DomEvent.on(this.canvas, 'click', this.handleClick, this);
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentwillunmount}
   * @return {void}
   */
  componentWillUnmount() {
    this.context = null;

    L.DomEvent.off(this.canvas);
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidupdate}
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.horizontal !== prevProps.horizontal) {
      this.drawHorizontal();
    }

    if (!isEqual(this.props.flightpath, prevProps.flightpath)) {
      delete this.topography;
      delete this.icing;
      delete this.isotherms;
      delete this.waypoints;
    }

    if (
      this.props.forecastRun !== prevProps.forecastRun ||
      this.props.currentTime !== prevProps.currentTime ||
      !isEqual(this.props.topography, prevProps.topography) ||
      !isEqual(this.props.icing, prevProps.icing) ||
      !isEqual(this.props.isotherms, prevProps.isotherms) ||
      !isEqual(this.props.flightpath, prevProps.flightpath)
    ) {
      this.context = this.canvas.getContext('2d');
      this.draw();
    }
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#render}
   */
  render() {
    return (
      <div className="crosssection-image">
        <canvas ref={(node) => (this.canvas = node)}></canvas>

        <div
          className="crosssection-image-altitude"
          ref={(node) => (this.altitudeLine = node)}
        ></div>
      </div>
    );
  }
}

/**
 * @param {object} state
 * @param {object} ownProps
 * @return {object}
 */
function mapStateToProps(state, ownProps) {
  return {
    flightpath: state.flightpath.waypoints,
    currentTime: state.widget.currentTime,
    forecastRun: state.forecasts.current,
    horizontal: state.widget.altitude,
    topography: state.topography,
    icing: state.icing,
    cloud: state.cloud,
    isotherms: state.isotherms,
  };
}

/**
 * @param {function} dispatch
 * @param {object} ownProps
 * @return {object}
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    updateAltitude: (altitude) => dispatch(updateAltitude(altitude)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CrosssectionCanvas);
