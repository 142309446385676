/**
 * @module react/components/crosssection/crosssection-loading
 */
import React from 'react';

/**
 * @param {object} props
 */
export default function CrosssectionLoading(props) {
  return (
    <div className="crosssection-loading">
      <p>Loading...</p>
    </div>
  );
}
