/**
 * @module react/components/forecasts/forecasts-fetch
 */
import React from 'react';

/**
 *
 */
export default function ForecastsFetch(props) {
  let { info } = props;

  return (
    <div className="forecasts">
      <div className="forecasts-fetch">
        <div className="forecasts-fetch-icon">
          <img src={info.icon} alt="" width="50" height="50" />
        </div>

        <div className="forecasts-fetch-message">
          <strong>{info.title}</strong>
          <p>{info.message}</p>
        </div>

        <div className="forecasts-fetch-cta">
          <button
            className="button"
            onClick={info.action.onClick}
            type="button"
          >
            {info.action.label}
          </button>
        </div>
      </div>
    </div>
  );
}
