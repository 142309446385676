import L from 'leaflet';
import noop from 'lodash/noop';

let types = {
  rega: 'Rega base',
  military: 'Military base',
  heliport: 'Aerodrome / Heliport',
  hospital: 'Hospital',
  route: 'Air-traffic waypoint',
};

L.layerGroup.presetWaypoints = function (options) {
  return new L.LayerGroup.PresetWaypoints(options);
};

L.LayerGroup.PresetWaypoints = L.LayerGroup.extend({
  options: {
    clickHandler: noop,
  },

  /**
   * @constructs PresetWaypoints
   * @param {object} options
   * @param {function} options.clickHandler
   */
  initialize: function (options) {
    L.Util.setOptions(this, options);

    L.LayerGroup.prototype.initialize.call(this, options);

    this._waypoints = [];
  },

  /**
   * @memberof PresetWaypoints
   * @param {L.Map} map
   */
  onAdd: function (map) {
    L.LayerGroup.prototype.onAdd.call(this, map);

    this._update();
  },

  /**
   * @memberof PresetWaypoints
   * @param {L.Map} map
   */
  onRemove: function (map) {
    L.LayerGroup.prototype.onRemove.call(this, map);
  },

  /**
   * @memberof PresetWaypoints
   * @param {array} waypoints
   */
  setData: function (waypoints) {
    this._waypoints = waypoints;

    this._update();
  },

  /**
   * @memberof PresetWaypoints
   */
  _update: function () {
    this.clearLayers();

    this._waypoints.forEach((waypoint) => {
      let position = L.latLng(waypoint.lat, waypoint.lng);

      let icon = L.divIcon({
        className: 'presets presets-' + waypoint.type,
      });

      let marker = L.marker(position, { icon });

      marker.on('click', (event) => {
        this.options.clickHandler({
          preset: waypoint.id,
          name: waypoint.name,
          lat: waypoint.lat,
          lng: waypoint.lng,
        });
      });

      marker.bindTooltip(
        L.Util.template('<strong>{name}</strong>', {
          name: waypoint.name,
          type: types[waypoint.type],
        })
      );

      this.addLayer(marker);
    });
  },
});
