/**
 * @module react/containers/flightpath
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import feedback from '../../img/feedback.svg';

import FlightpathList from './flightpath-list';
import {
  clearWaypoints,
  showWaypointForm,
  showHelp,
} from '../../redux/actions/flightpath';

/**
 *
 */
class Flightpath extends Component {
  /**
   * @param {object} props
   */

  //   ____                 _
  // |  _ \ ___  __ _  ___| |_
  // | |_) / _ \/ _` |/ __| __|
  // |  _ <  __/ (_| | (__| |_
  // |_| \_\___|\__,_|\___|\__|
  //

  /**
   * @param {object} nextProps
   * @param {object} nextState
   */
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.waypoints, nextProps.waypoints);
  }

  /**
   * @returns {object} React element
   */
  render() {
    return (
      <div className="waypoints">
        <div className="waypoints-list">
          <FlightpathList
            waypoints={this.props.waypoints}
            editWaypoint={this.props.editWaypoint}
          />
        </div>

        <div className="waypoints-controls">
          <button
            className="controls-add"
            type="button"
            onClick={this.props.onAddClick}
          >
            Add
          </button>
          <button
            className="controls-clear"
            type="button"
            onClick={this.props.onClearClick}
          >
            Clear
          </button>
          <button
            className="controls-help"
            type="button"
            onClick={this.props.onHelpClick}
          >
            Help
          </button>
          <button
            className="controls-feedback"
            type="button"
            onClick={() =>
              window.open(
                'https://docs.google.com/forms/d/e/1FAIpQLSd4uGLGqtEz0Gx5Gd19BboBfstFnOS5V3Rn_6TOL6xgQWadHw/viewform',
                '_blank'
              )
            }
          >
            <img src={feedback} alt="feedback link"></img>
          </button>
        </div>
      </div>
    );
  }
}

/**
 * @param {object} state
 * @param {object} ownProps
 */
function mapStateToProps(state, ownProps) {
  return {
    waypoints: state.flightpath.waypoints,
  };
}

/**
 * @param {function} dispatch
 * @param {object} ownProps
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAddClick: () => dispatch(showWaypointForm()),
    onClearClick: () => dispatch(clearWaypoints()),
    onHelpClick: () => dispatch(showHelp()),
    editWaypoint: (waypoint) => dispatch(showWaypointForm(waypoint)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Flightpath);
