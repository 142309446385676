import moment from 'moment';
import queryString from 'query-string';
import { createLogic } from 'redux-logic';

import { ALTITUDES, GRIDAPI_BASEURI } from '../../config/config-base';

import {
  ICING_XHR_DISPATCH,
  ICING_XHR_CANCEL,
  ICING_XHR_ERROR,
  ICING_XHR_SUCCESS,
} from '../actions/icing';

const icingRequestEpic = createLogic({
  type: ICING_XHR_DISPATCH,
  cancelType: ICING_XHR_CANCEL,
  latest: true,

  processOptions: {
    dispatchReturn: true,
    successType: ICING_XHR_SUCCESS,
    failType: ICING_XHR_ERROR,
  },

  process({ getState, action, ajax }) {
    let { init, time, latlngs } = action.payload;

    let settings = {
      crossDomain: true,
      responseType: 'json',
      url:
        `${GRIDAPI_BASEURI}/cross_section?` +
        queryString.stringify({
          name: 'icon1_ch_icing',
          params: ALTITUDES.map(
            (altitude) => `icing_intensity_${altitude}m`
          ).join(','),
          init: moment.utc(init).format('YYYY-MM-DD HH:mm:ss'),
          time: moment.utc(time).format('YYYY-MM-DD HH:mm:ss'),
          lats: latlngs.map((latlng) => latlng.lat).join(','),
          lons: latlngs.map((latlng) => latlng.lon).join(','),
        }),
    };

    return ajax(settings);
  },
});

const icingEpic = [icingRequestEpic];

export default icingEpic;
