/**
 * @module redux/actions/isotherms
 */

export const ISOTHERMS_RESET = 'ISOTHERMS_RESET';
export const ISOTHERMS_XHR_DISPATCH = 'ISOTHERMS_XHR_DISPATCH';
export const ISOTHERMS_XHR_CANCEL = 'ISOTHERMS_XHR_CANCEL';
export const ISOTHERMS_XHR_ERROR = 'ISOTHERMS_XHR_ERROR';
export const ISOTHERMS_XHR_SUCCESS = 'ISOTHERMS_XHR_SUCCESS';

/**
 * Restores the initial state.
 * @returns {FluxStandardAction}
 */
export function resetIsotherms() {
  return { type: ISOTHERMS_RESET };
}

/**
 * @param {number} init
 * @param {number} time
 * @param {object[]} latlngs
 * @returns {FluxStandardAction}
 */
export function dispatchIsothermsRequest(init, time, latlngs) {
  return {
    type: ISOTHERMS_XHR_DISPATCH,
    payload: { init, time, latlngs },
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function cancelIsothermsRequest(error) {
  return {
    type: ISOTHERMS_XHR_CANCEL,
    payload: error,
    error: true,
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function isothermsRequestFailed(error) {
  return {
    type: ISOTHERMS_XHR_ERROR,
    payload: error,
    error: true,
  };
}

/**
 * @param {object} response
 * @returns {FluxStandardAction}
 */
export function isothermsRequestSuccessful(response) {
  return {
    type: ISOTHERMS_XHR_SUCCESS,
    payload: response,
  };
}
