/**
 * @module react/components/forecasts/forecasts-item
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { changeForecastsSelection } from '../../redux/actions/forecasts';

/**
 *
 */
class ForecastsItem extends Component {
  /**
   * @see {@link https://reactjs.org/docs/react-component.html#constructor}
   * @param {object} props
   */
  constructor(props) {
    super(props);

    let when = moment.utc(this.props.run);
    this.time = when.format('HH:mm');
    this.date = when.format('DD.MM.YYYY');

    this.onClickHandler = this.onClick.bind(this);
  }

  /**
   * @param {SyntheticEvent} event
   * @return {void}
   */
  onClick(event) {
    this.props.changeSelection(this.props.run);
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidupdate}
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.run !== prevProps.run) {
      let when = moment.utc(this.props.run);
      this.time = when.format('HH:mm');
      this.date = when.format('DD.MM.YYYY');
    }
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#render}
   */
  render() {
    return (
      <li
        className={
          'forecast' +
          (this.props.current ? ' forecast--current' : '') +
          (this.props.current && this.props.newest ? ' forecast--newest' : '')
        }
        onClick={this.onClickHandler}
      >
        <span className="forecast-date">{this.date}</span>
        <span className="forecast-time">{this.time}</span>
        <span className="forecast-zone">UTC</span>
      </li>
    );
  }
}

/**
 *
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    changeSelection: (run) => dispatch(changeForecastsSelection(run)),
  };
}

export default connect(null, mapDispatchToProps)(ForecastsItem);
