import topographyLogics from './topography';
import icingLogics from './icing';
import cloudLogics from './cloud';
import isothermsLogics from './isotherms';

const logics = [
  ...topographyLogics,
  ...icingLogics,
  ...cloudLogics,
  ...isothermsLogics,
];

export default logics;
