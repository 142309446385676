import moment from 'moment-timezone';
import L from 'leaflet';

import 'leaflet-fullscreen';
import 'leaflet-control-timeslider';
import 'leaflet-control-playback';
import 'leaflet-control-layerslider';
import './legend/legend-icing';
import './legend/legend-preset';
import { metersToFeet } from '../../utility/convert';

let control_timeslider;
export function timeslider(widget_instance) {
  if (!control_timeslider) {
    control_timeslider = L.control.timeSlider(
      {
        name: 'timeslider',
        format: (timestamp) => moment.utc(timestamp).format('DD.MM. HH:mm z'),
      },
      widget_instance
    );
  }

  return control_timeslider;
}

let control_playback;
export function playback(widget_instance) {
  if (!control_playback) {
    control_playback = L.control.playback(
      {
        name: 'playback',
      },
      widget_instance
    );
  }

  return control_playback;
}

let control_fullscreen;
export function fullscreen() {
  if (!control_fullscreen) {
    control_fullscreen = L.control.fullscreen({
      name: 'fullscreen',
      //			pseudoFullscreen: true,
    });
  }

  return control_fullscreen;
}

let control_layerslider;
export function layerslider(widget_instance) {
  if (!control_layerslider) {
    control_layerslider = L.control.layerSlider(
      {
        name: 'layerslider',
        group: 'icing',
        height: '100%',
        formatTo: function (value) {
          let meters = this._layers[Math.round(value)].options.title;
          let feet = Math.round(metersToFeet(meters) / 10) * 10;
          return `${feet} ft<br>${meters} m<br>AMSL`;
        },
      },
      widget_instance
    );
  }

  return control_layerslider;
}

let control_icinglegend;
export function icinglegend() {
  if (!control_icinglegend) {
    control_icinglegend = L.control.icingLegend({
      name: 'icinglegend',
    });
  }

  return control_icinglegend;
}

let control_presetslegend;
export function presetslegend() {
  if (!control_presetslegend) {
    control_presetslegend = L.control.presetsLegend({
      name: 'presetslegend',
    });
  }

  return control_presetslegend;
}
