/**
 * @module redux/reducers/widget
 */
import moment from 'moment';

import { ALTITUDES } from '../../config/config-base';

import {
  WIDGET_SYNC_STARTSTOPTIME,
  WIDGET_SYNC_CURRENTTIME,
  WIDGET_SYNC_NOWTIME,
  WIDGET_SYNC_ALTITUDE,
  WIDGET_SYNC_VIEW,
} from '../actions/widget';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  altitude: ALTITUDES[0],
  center: [46.6935699970962, 8.33191805023102],
  zoom: 7.5,
  currentTime: moment.utc().startOf('hour').valueOf(),
  nowTime: moment.utc().valueOf(),
  startTime: moment.utc().startOf('hour').subtract(12, 'h').valueOf(),
  stopTime: moment.utc().startOf('hour').add(36, 'h').valueOf(),
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateStartStopTime(state, action) {
  return Object.assign({}, state, {
    startTime: action.payload.start,
    stopTime: action.payload.stop,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateCurrentTime(state, action) {
  return Object.assign({}, state, {
    currentTime: action.payload.current,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateNowTime(state, action) {
  return Object.assign({}, state, {
    nowTime: action.payload.now,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateAltitude(state, action) {
  return Object.assign({}, state, {
    altitude: parseInt(action.payload.altitude, 10),
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateView(state, action) {
  return Object.assign({}, state, {
    center: action.payload.center,
    zoom: action.payload.zoom,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function widgetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WIDGET_SYNC_STARTSTOPTIME:
      return updateStartStopTime(state, action);

    case WIDGET_SYNC_CURRENTTIME:
      return updateCurrentTime(state, action);

    case WIDGET_SYNC_NOWTIME:
      return updateNowTime(state, action);

    case WIDGET_SYNC_ALTITUDE:
      return updateAltitude(state, action);

    case WIDGET_SYNC_VIEW:
      return updateView(state, action);

    default:
      return state;
  }
}
