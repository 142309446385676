import { CHtoWGSlat, CHtoWGSlng } from './wgs84_ch1903';
import { parseDms } from './dms-conversion';

/** Normalize waypoint list
 *
 * Due to the heterogeneity of the data sources, the coordinate fields are
 * not all in the same format. Also, some fields are missing and others
 * need cleaning. The following fields are read per waypoint:
 *
 * - Convert WGS84{N,E} fields from deg, min, sec format into decimal degrees
 * - Convert NatGrid{Y,X} into decimal degrees
 * - Use WGS84 fields for lat, lng if available, otherwise use NatGrid fields
 * - Copy the name field
 * - Copy the type field
 */

export default function normalize(waypoints) {
  const REGEX_NORTH = /(\d{2})(\d{2})(\d{2})(\d+)/;
  const REGEX_EAST = /(\d{3})(\d{2})(\d{2})(\d+)/;

  let output = [];
  let nextid = 1;

  waypoints.forEach((waypoint) => {
    let lat, lng, north, east;

    if (waypoint.WGS84N === null) {
      north = waypoint.NatGridY;
      east = waypoint.NatGridX;

      lat = CHtoWGSlat(north, east);
      lng = CHtoWGSlng(north, east);
    } else {
      north = waypoint.WGS84N;
      east = waypoint.WGS84E;

      if (+north > 0) {
        let [, deg, mins, sec, dez] = north.match(REGEX_NORTH);
        north = `${deg}°${mins}'${sec}.${dez}"`;
      }

      lat = parseDms(north);

      if (+east > 0) {
        let [, deg, mins, sec, dez] = east.match(REGEX_EAST);
        east = `${deg}°${mins}'${sec}.${dez}"`;
      }

      lng = parseDms(east);
    }

    output.push({
      id: nextid++,
      name: waypoint.LocDes,
      type: waypoint.Type,
      // https://gis.stackexchange.com/a/8674
      lat: lat.toFixed(6),
      lng: lng.toFixed(6),
    });
  });

  return output;
}
