import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

L.control.presetsLegend = function (options) {
  return new L.Control.PresetsLegend(options);
};

L.Control.PresetsLegend = L.Control.extend({
  options: {
    position: 'bottomleft',
    categories: [
      { name: 'rega', label: 'Rega base' },
      { name: 'military', label: 'Military base' },
      { name: 'heliport', label: 'Aerodrome / Heliport' },
      { name: 'hospital', label: 'Hospital' },
      { name: 'route', label: 'Air-traffic waypoint' },
    ],
  },

  /**
   * @constructs PresetsLegend
   * @param {object} options
   * @param {string} options.position
   * @param {array} options.categories
   */
  initialize: function (options) {
    L.Util.setOptions(this, options);
  },

  /**
   * @memberof PresetsLegend
   * @param {L.Map} map
   */
  onAdd: function (map) {
    let container = L.DomUtil.create('div', 'widget-control-presetslegend');

    container.innerHTML = ReactDOMServer.renderToString(
      <ul className="presetslegend-categories">
        {this.options.categories.map((category, index) => (
          <li key={index} className="presetslegend-category">
            <span
              className={`presetslegend-color presetslegend-color-${category.name}`}
            ></span>{' '}
            {category.label}
          </li>
        ))}
      </ul>
    );

    return container;
  },
});
