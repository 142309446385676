import difference from 'lodash/difference';
import L from 'leaflet';

import 'leaflet-layer-gridapi';
import './layergroup/layergroup-presetwaypoints';
import './layergroup/layergroup-flightpath';

import { ALTITUDES, GRIDAPI_BASEURI } from '../../config/config-base';

let layer_background;
export function background() {
  if (!layer_background) {
    layer_background = L.tileLayer(
      'https://api.mapbox.com/styles/v1/{map}/tiles/256/{z}/{x}/{y}?access_token={token}',
      {
        name: 'background',
        token:
          'pk.eyJ1IjoibWV0ZW90ZXN0IiwiYSI6ImNsOXBtZGZ0dTAxcTc0MnFycTBmMDY4eW4ifQ.HBKBK_-gARn-8S7IxNhYYw',
        map: 'meteotest/clv6exu6s00mg01pkf8zebxiy',
        attribution:
          '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> ' +
          '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
  }

  return layer_background;
}

let layer_foreground;
export function foreground() {
  if (!layer_foreground) {
    layer_foreground = L.tileLayer(
      'https://api.mapbox.com/styles/v1/{map}/tiles/256/{z}/{x}/{y}?access_token={token}',
      {
        name: 'foreground',
        token:
          'pk.eyJ1IjoibWV0ZW90ZXN0IiwiYSI6Ik04ZXJpZm8ifQ.7bC_VfpMUnbPlbn1F22ijA',
        map: 'meteotest/clw4okjr202kz01qzd7zjft94',
        attribution:
          '© <a href="https://www.mapbox.com/contribute/">Mapbox</a> ' +
          '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        zIndex: 4,
      }
    );
  }

  return layer_foreground;
}

let layer_icing = {};
export function icing(altitude, init) {
  if (!layer_icing[altitude]) {
    layer_icing[altitude] = L.gridLayer.gridApi({
      name: 'icing_' + altitude,
      title: altitude,
      group: 'icing',
      excludedLayers: difference(ALTITUDES, [+altitude]).map(
        (alt) => 'icing_' + alt
      ),

      init,
      baseUrl: `${GRIDAPI_BASEURI}/`,
      wmtsName: 'icon1_ch_icing_' + altitude + 'm',
      // scale tiles in the browser for zoom levels >= 9
      scaleTilesZoomGeq: 8,
      tileSize: 256,
      opacity: 0.7,
      zIndex: 3,

      //			preloading: true,
    });
  }

  return layer_icing[altitude];
}

let layer_cloud = {};
export function cloud(altitude, init) {
  if (!layer_cloud[altitude]) {
    layer_cloud[altitude] = L.gridLayer.gridApi({
      name: 'cloud_' + altitude,
      title: altitude,
      group: 'cloud',
      excludedLayers: difference(ALTITUDES, [+altitude]).map(
        (alt) => 'cloud_' + alt
      ),

      init,
      baseUrl: `${GRIDAPI_BASEURI}/`,
      wmtsName: 'icon1_ch_cloud_' + altitude + 'm',
      // scale tiles in the browser for zoom levels >= 9
      scaleTilesZoomGeq: 8,
      tileSize: 256,
      opacity: 0.7,
      zIndex: 2,
    });
  }

  return layer_cloud[altitude];
}

let layer_presets;
export function presets(clickHandler) {
  if (!layer_presets) {
    layer_presets = L.layerGroup.presetWaypoints({
      name: 'presets',
      clickHandler,
    });
  }

  return layer_presets;
}

let layer_flightpath;
export function flightpath(clickHandler) {
  if (!layer_flightpath) {
    layer_flightpath = L.layerGroup.flightpath({
      name: 'flightpath',
      clickHandler,
    });
  }

  return layer_flightpath;
}
