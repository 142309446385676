/*
 * The base URI of the GridAPI.
 * This is used for the Leaflet layers, forecast selection, and cross sections.
 */
export { GRIDAPI_BASEURI } from './gridapi_url';

/**
 * The names and colors of the different icing levels.
 */
export const ICING_LEVELS = [
  { label: 'Light icing', color: '#00cff3' },
  { label: 'Moderate icing', color: '#006fd9' },
  { label: 'Severe icing', color: '#ff00ff' },
];

/**
 * A mapping for the keyboard keys in use by the app.
 * The names are derived from the DOM Level 3 Events spec.
 * @see https://www.w3.org/TR/uievents-key/#named-key-attribute-values
 */
export const KEYCODES = {
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
};

/**
 * The altitudes provided for the cross section and map tiles.
 */
export const ALTITUDES = [
  6401, // 21000 ft
  6096, // 20000 ft
  5791, // 19000 ft
  5486, // 18000 ft
  5182, // 17000 ft
  4877, // 16000 ft
  4572, // 15000 ft
  4267, // 14000 ft
  3962, // 13000 ft
  3658, // 12000 ft
  3505, // 11500 ft
  3353, // 11000 ft
  3200, // 10500 ft
  3048, // 10000 ft
  2896, //  9500 ft
  2743, //  9000 ft
  2591, //  8500 ft
  2438, //  8000 ft
  2286, //  7500 ft
  2134, //  7000 ft
  1981, //  6500 ft
  1829, //  6000 ft
  1676, //  5500 ft
  1524, //  5000 ft
  1448, //  4750 ft
  1372, //  4500 ft
  1295, //  4250 ft
  1219, //  4000 ft
  1143, //  3750 ft
  1067, //  3500 ft
  991, //  3250 ft
  914, //  3000 ft
  838, //  2750 ft
  762, //  2500 ft
  686, //  2250 ft
  610, //  2000 ft
  533, //  1750 ft
  457, //  1500 ft
  381, //  1250 ft
  305, //  1000 ft
  229, //   750 ft
  152, //   500 ft
  76, //   250 ft
];
