/**
 * @module react/components/forecasts/forecasts-list
 */
import React, { Component } from 'react';

import ForecastsItem from './item';

/**
 *
 */
export default class ForecastsList extends Component {
  /**
   * @see {@link https://reactjs.org/docs/react-component.html#constructor}
   * @param {object} props
   */

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidmount}
   * @return {void}
   */
  componentDidMount() {
    let node = this.container.querySelector('.forecast--current');

    if (node) {
      let parent = this.container.parentNode;
      let offset = parent.offsetHeight / 2 - node.offsetHeight / 2;

      node.scrollIntoView();
      parent.scrollTop -= offset;
    }
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#render}
   */
  render() {
    return (
      <ul ref={(node) => (this.container = node)}>
        {this.props.runs.map((run, index) => (
          <ForecastsItem
            key={index}
            run={run}
            newest={index === 0}
            current={run === this.props.current}
          />
        ))}
      </ul>
    );
  }
}
