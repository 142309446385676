/**
 * @module redux/actions/forecasts
 */
import axios from 'axios';

import { ALTITUDES, GRIDAPI_BASEURI } from '../../config/config-base';

export const FORECASTS_XHR_SEND = 'FORECASTS_XHR_SEND';
export const FORECASTS_XHR_ERROR = 'FORECASTS_XHR_ERROR';
export const FORECASTS_XHR_CANCEL = 'FORECASTS_XHR_CANCEL';
export const FORECASTS_XHR_LOAD = 'FORECASTS_XHR_LOAD';
export const FORECASTS_SELECTION_CHANGED = 'FORECASTS_SELECTION_CHANGED';

const API = axios.create({
  baseURL: `${GRIDAPI_BASEURI}/wmts/icon1_ch_icing_${ALTITUDES[0]}m`,
});

/**
 * @param {object} data
 * @returns {FluxStandardAction}
 */
export function forecastsRequestLoaded(data) {
  return {
    type: FORECASTS_XHR_LOAD,
    payload: { data },
  };
}

/**
 * @returns {FluxStandardAction}
 */
export function forecastsRequestFailed() {
  return { type: FORECASTS_XHR_ERROR };
}

/**
 * @returns {FluxStandardAction}
 */
export function forecastsRequestAborted() {
  return { type: FORECASTS_XHR_CANCEL };
}

/**
 * @param {function} cancel Axios cancel token
 * @returns {FluxStandardAction}
 */
export function forecastsRequestIssued(cancel) {
  return {
    type: FORECASTS_XHR_SEND,
    payload: { cancel },
  };
}

/**
 *
 */
export function issueForecastsRequest() {
  return function (dispatch, getState) {
    dispatch(cancelForecastsRequest());

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let request_options = { cancelToken: source.token };

    API.get('/list_init_times', request_options).then(
      (response) => {
        dispatch(forecastsRequestLoaded(response.data));
      },
      (error) => {
        if (axios.isCancel(error)) {
          dispatch(forecastsRequestAborted(error));
        } else {
          dispatch(forecastsRequestFailed(error));
        }
      }
    );

    dispatch(forecastsRequestIssued(source.cancel));
  };
}

/**
 * @param {string} message
 */
export function cancelForecastsRequest(message) {
  return function (dispatch, getState) {
    let { cancel } = getState().forecasts;

    if (typeof cancel === 'function') {
      cancel(message);

      dispatch(forecastsRequestAborted());
    }
  };
}

/**
 * @param {number} run Javascript timestamp of run initializsation.
 */
export function changeForecastsSelection(run) {
  return {
    type: FORECASTS_SELECTION_CHANGED,
    payload: { current: run },
  };
}
