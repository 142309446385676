/**
 * @module react/components/flightpath/flightpath-waypoints
 */
import React, { Component } from 'react';

/**
 *
 */
export default class FlightpathWaypoint extends Component {
  /**
   * @see {@link https://reactjs.org/docs/react-component.html#constructor}
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.onEditHandler = this.onEdit.bind(this);
  }

  /**
   * @param {SyntheticEvent} event
   * @return {void}
   */
  onEdit(event) {
    this.props.editWaypoint(this.props.waypoint);
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#render}
   */
  render() {
    return (
      <li className="waypoint">
        <div>
          <span className="waypoint-title">
            Waypoint {this.props.waypoint.index + 1}
            {this.props.waypoint.name && ' (' + this.props.waypoint.name + ')'}
          </span>

          <dl className="waypoint-location">
            <dt className="waypoint-lat-title">lat.</dt>
            <dd className="waypoint-lat-value">{this.props.waypoint.lat}</dd>

            <dt className="waypoint-lng-title">lng.</dt>
            <dd className="waypoint-lng-value">{this.props.waypoint.lng}</dd>
          </dl>
        </div>

        <button
          className="waypoint-edit"
          type="button"
          onClick={this.onEditHandler}
        >
          edit
        </button>
      </li>
    );
  }
}
