/**
 * This module is used to sync the Redux store with localStorage.
 * @module persistentstate
 */

const REGEX_SCALARFIELD = /\d{11,14}\.\d{11,14}/;

function reviver(key, value) {
  if (REGEX_SCALARFIELD.test(key)) {
    // null values will produce ugly effects in the cross section.
    value = value.map((item) => (item === null ? undefined : item));
  }

  return value;
}

// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage

/**
 * @returns {(object|undefined)} The deserialized state from localStorage, if present.
 */
export function loadState() {
  try {
    let serialized = localStorage.getItem('state');

    if (serialized !== null) {
      return JSON.parse(serialized, reviver);
    }
  } catch (error) {
    /* no-op */
  }
}

/**
 * @param {object} state The state from Redux to be stored.
 */
export function saveState(state) {
  try {
    let serialized = JSON.stringify(state);

    localStorage.setItem('state', serialized);
  } catch (error) {
    /* no-op */
  }
}
