import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import CrosssectionIntro from './crosssection-intro';
import CrosssectionLoading from './crosssection-loading';
import CrosssectionCanvas from './crosssection-canvas';

import {
  dispatchTopographyRequest,
  cancelTopographyRequest,
  resetTopography,
} from '../../redux/actions/topography';
import {
  dispatchIcingRequest,
  cancelIcingRequest,
  resetIcing,
} from '../../redux/actions/icing';
import {
  dispatchCloudRequest,
  cancelCloudRequest,
  resetCloud,
} from '../../redux/actions/cloud';
import {
  dispatchIsothermsRequest,
  cancelIsothermsRequest,
  resetIsotherms,
} from '../../redux/actions/isotherms';

/**
 * @extends {React.Component}
 */
class Crosssection extends Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.setStatus(this.props);
  }

  /**
   * @param {object} props
   */
  setStatus(props) {
    let status = 'empty';

    if (props.waypoints.length > 1) {
      status = props.icingStatus === 'loading' ? 'loading' : 'ready';
    }

    this.setState({ status });
  }

  /**
   * @param {object} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.waypoints.length > 1) {
      let latlngs = nextProps.waypoints.map((waypoint) => ({
        lat: waypoint.lat,
        lon: waypoint.lng,
      }));

      if (nextProps.currentTime !== this.props.currentTime) {
        this.props.cancelIcing(new Error('Current time has changed.'));
        this.props.cancelCloud(new Error('Current time has changed.'));
        this.props.cancelIsotherms(new Error('Current time has changed.'));

        if (nextProps.icingValues === undefined) {
          this.props.fetchIcing(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }

        if (nextProps.cloudValues === undefined) {
          this.props.fetchCloud(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }

        if (nextProps.isothermsValues === undefined) {
          this.props.fetchIsotherms(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }
      } else if (!isEqual(nextProps.waypoints, this.props.waypoints)) {
        this.props.cancelTopography(new Error('Flightpath has changed.'));
        this.props.cancelIcing(new Error('Flightpath has changed.'));
        this.props.cancelCloud(new Error('Flightpath has changed.'));
        this.props.cancelIsotherms(new Error('Flightpath has changed.'));

        this.props.resetTopography();
        this.props.resetIcing();
        this.props.resetCloud();
        this.props.resetIsotherms();
      } else {
        let threshold = moment.utc().valueOf() - 5000;

        if (
          nextProps.topographyStatus !== 'loading' &&
          nextProps.topographyAltitudes.length === 0 &&
          !(
            nextProps.topographyStatus === 'failed' &&
            nextProps.topographyLast > threshold
          )
        ) {
          this.props.fetchTopography(latlngs);
        }

        if (
          nextProps.icingStatus !== 'loading' &&
          nextProps.icingValues === undefined &&
          !(
            nextProps.icingStatus === 'failed' &&
            nextProps.icingLast > threshold
          )
        ) {
          this.props.fetchIcing(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }

        if (
          nextProps.cloudStatus !== 'loading' &&
          nextProps.cloudValues === undefined &&
          !(
            nextProps.cloudStatus === 'failed' &&
            nextProps.cloudLast > threshold
          )
        ) {
          this.props.fetchCloud(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }

        if (
          nextProps.isothermsStatus !== 'loading' &&
          nextProps.isothermsValues === undefined &&
          !(
            nextProps.isothermsStatus === 'failed' &&
            nextProps.isothermsLast > threshold
          )
        ) {
          this.props.fetchIsotherms(
            nextProps.forecastRun,
            nextProps.currentTime,
            latlngs
          );
        }
      }
    }

    this.setStatus(nextProps);
  }

  /**
   *
   */
  render() {
    switch (this.state.status) {
      case 'empty':
        return (
          <div className="crosssection">
            <CrosssectionIntro />
          </div>
        );

      case 'loading':
        return (
          <div className="crosssection">
            <CrosssectionLoading />
          </div>
        );

      case 'ready':
        return (
          <div className="crosssection">
            <CrosssectionCanvas />
          </div>
        );

      default:
        return null;
    }
  }
}

/**
 * @param {object} state
 * @param {object} ownProps
 */
function mapStateToProps(state, ownProps) {
  const scalar_id = state.forecasts.current + '.' + state.widget.currentTime;
  return {
    nowTime: state.widget.nowTime,
    currentTime: state.widget.currentTime,
    forecastRun: state.forecasts.current,
    waypoints: state.flightpath.waypoints,

    icingStatus: state.icing.status,
    icingLast: state.icing.lastFetch,
    icingValues: state.icing.scalarfields[scalar_id],

    cloudStatus: state.cloud.status,
    cloudLast: state.cloud.lastFetch,
    cloudValues: state.cloud.scalarfields[scalar_id],

    isothermsStatus: state.isotherms.status,
    isothermsLast: state.isotherms.lastFetch,
    isothermsValues: state.isotherms.scalarfields[scalar_id],

    topographyStatus: state.topography.status,
    topographyLast: state.topography.lastFetch,
    topographyAltitudes: state.topography.altitudes,
  };
}

/**
 * @param {function} dispatch
 * @param {object} ownProps
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchTopography: (latlngs) => dispatch(dispatchTopographyRequest(latlngs)),
    cancelTopography: (message) => dispatch(cancelTopographyRequest(message)),
    resetTopography: () => dispatch(resetTopography()),

    fetchIcing: (init, time, latlngs) =>
      dispatch(dispatchIcingRequest(init, time, latlngs)),
    cancelIcing: (message) => dispatch(cancelIcingRequest(message)),
    resetIcing: () => dispatch(resetIcing()),

    fetchCloud: (init, time, latlngs) =>
      dispatch(dispatchCloudRequest(init, time, latlngs)),
    cancelCloud: (error) => dispatch(cancelCloudRequest(error)),
    resetCloud: () => dispatch(resetCloud()),

    fetchIsotherms: (init, time, latlngs) =>
      dispatch(dispatchIsothermsRequest(init, time, latlngs)),
    cancelIsotherms: (error) => dispatch(cancelIsothermsRequest(error)),
    resetIsotherms: () => dispatch(resetIsotherms()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Crosssection);
