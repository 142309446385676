import queryString from 'query-string';
import { createLogic } from 'redux-logic';

import {
  TOPOGRAPHY_XHR_DISPATCH,
  TOPOGRAPHY_XHR_CANCEL,
  TOPOGRAPHY_XHR_ERROR,
  TOPOGRAPHY_XHR_SUCCESS,
} from '../actions/topography';

import { GRIDAPI_BASEURI } from '../../config/config-base';

const topographyRequestEpic = createLogic({
  type: TOPOGRAPHY_XHR_DISPATCH,
  cancelType: TOPOGRAPHY_XHR_CANCEL,
  latest: true,

  processOptions: {
    dispatchReturn: true,
    successType: TOPOGRAPHY_XHR_SUCCESS,
    failType: TOPOGRAPHY_XHR_ERROR,
  },

  process({ getState, action, ajax }) {
    let { latlngs } = action.payload;

    let settings = {
      crossDomain: true,
      responseType: 'json',
      url:
        `${GRIDAPI_BASEURI}/cross_section?` +
        queryString.stringify({
          name: 'srtmgl1_100-eu60',
          params: 'orography',
          lats: latlngs.map((latlng) => latlng.lat).join(','),
          lons: latlngs.map((latlng) => latlng.lon).join(','),
        }),
    };

    return ajax(settings);
  },
});

const topographyEpic = [topographyRequestEpic];

export default topographyEpic;
