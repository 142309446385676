import { combineReducers } from 'redux';

import flightpathReducer from './flightpath';
import forecastsReducer from './forecasts';
import icingReducer from './icing';
import cloudReducer from './cloud';
import isothermsReducer from './isotherms';
import presetsReducer from './presets';
import topographyReducer from './topography';
import widgetReducer from './widget';

export default combineReducers({
  flightpath: flightpathReducer,
  forecasts: forecastsReducer,
  icing: icingReducer,
  cloud: cloudReducer,
  isotherms: isothermsReducer,
  presets: presetsReducer,
  topography: topographyReducer,
  widget: widgetReducer,
});
