import L from 'leaflet';
import noop from 'lodash/noop';

L.layerGroup.flightpath = function (options) {
  return new L.LayerGroup.Flightpath(options);
};

L.LayerGroup.Flightpath = L.LayerGroup.extend({
  options: {
    clickHandler: noop,
  },

  /**
   * @constructs Flightpath
   * @param {object} options
   * @param {function} options.clickHandler
   */
  initialize: function (options) {
    L.Util.setOptions(this, options);

    L.LayerGroup.prototype.initialize.call(this, options);

    this._waypoints = [];
    this._polyline = L.polyline([], {
      interactive: false,
      color: '#FF3131',
      weight: 2,
    });
  },

  /**
   * @memberof Flightpath
   * @param {L.Map} map
   */
  onAdd: function (map) {
    L.LayerGroup.prototype.onAdd.call(this, map);

    map.addLayer(this._polyline);
    this._update();
  },

  /**
   * @memberof Flightpath
   * @param {L.Map} map
   */
  onRemove: function (map) {
    L.LayerGroup.prototype.onRemove.call(this, map);

    map.removeLayer(this._polyline);
  },

  /**
   * @memberof Flightpath
   * @param {array} waypoints
   */
  setWaypoints(waypoints) {
    this._waypoints = waypoints;
    this._update();
  },

  /**
   * @memberof Flightpath
   * @param {object} waypoint
   */
  pushWaypoint(waypoint) {
    this._waypoints.push(waypoint);
    this._update();
  },

  /**
   * @memberof Flightpath
   */
  popWaypoint() {
    this._waypoints.pop();
    this._update();
  },

  /**
   * @memberof Flightpath
   */
  _update: function () {
    this.clearLayers();
    this._polyline.setLatLngs([]);

    this._waypoints.forEach((waypoint) => {
      let position = L.latLng(waypoint.lat, waypoint.lng);

      let icon = L.divIcon({
        className: 'widget-flightpath-waypoint',
        // The waypoint indices are zero-based.
        html: waypoint.index + 1,
      });

      let marker = L.marker(position, {
        icon,
        // Force the flightpath waypoints to be above the presets.
        zIndexOffset: 1000,
      });

      marker.on('click', (event) => this.options.clickHandler(waypoint));

      this.addLayer(marker);
      this._polyline.addLatLng(marker.getLatLng());
    });
  },
});
