import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import { ICING_LEVELS } from '../../../config/config-base';

L.control.icingLegend = function (options) {
  return new L.Control.IcingLegend(options);
};

L.Control.IcingLegend = L.Control.extend({
  options: {
    position: 'bottomleft',
    levels: ICING_LEVELS,
  },

  /**
   * @constructs IcingLegend
   * @param {object} options
   * @param {string} options.position
   * @param {array} options.levels
   */
  initialize: function (options) {
    L.Util.setOptions(this, options);
  },

  /**
   * @memberof IcingLegend
   * @param {L.Map} map
   */
  onAdd: function (map) {
    let container = L.DomUtil.create('div', 'widget-control-icinglegend');

    container.innerHTML = ReactDOMServer.renderToString(
      <ul className="icinglegend-levels">
        {this.options.levels.map((level, index) => (
          <li key={index} className="icinglegend-level">
            <span
              className="icinglegend-color"
              style={{ backgroundColor: level.color }}
            ></span>{' '}
            {level.label}
          </li>
        ))}
      </ul>
    );

    return container;
  },
});
