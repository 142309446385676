/**
 * @module redux/reducers/topography
 */

import moment from 'moment';

import {
  TOPOGRAPHY_RESET,
  TOPOGRAPHY_XHR_SUCCESS,
  TOPOGRAPHY_XHR_CANCEL,
  TOPOGRAPHY_XHR_ERROR,
  TOPOGRAPHY_XHR_DISPATCH,
} from '../actions/topography';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  status: 'empty',
  lastFetch: 0,

  distances: [],
  altitudes: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrDispatch(state, action) {
  return Object.assign({}, state, {
    status: 'loading',
    lastFetch: moment.utc().valueOf(),

    distances: [],
    altitudes: [],
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrCancel(state, action) {
  return Object.assign({}, state, {
    status: 'canceled',
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrError(state, action) {
  return Object.assign({}, state, {
    status: 'failed',
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrSuccess(state, action) {
  let samples = action.payload.response.samples;

  let distances = samples.map((sample) => Math.round(sample.dist));
  let altitudes = samples.map((sample) => Math.round(sample.values[0]));

  return Object.assign({}, state, {
    status: 'ready',

    distances,
    altitudes,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function topographyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOPOGRAPHY_RESET:
      return Object.assign({}, INITIAL_STATE);

    case TOPOGRAPHY_XHR_DISPATCH:
      return handleXhrDispatch(state, action);

    case TOPOGRAPHY_XHR_ERROR:
      return handleXhrError(state, action);

    case TOPOGRAPHY_XHR_CANCEL:
      return handleXhrCancel(state, action);

    case TOPOGRAPHY_XHR_SUCCESS:
      return handleXhrSuccess(state, action);

    default:
      return state;
  }
}
