/**
 * @module redux/reducers/forecasts
 */
import moment from 'moment';
import includes from 'lodash/includes';

import {
  FORECASTS_XHR_SEND,
  FORECASTS_XHR_ERROR,
  FORECASTS_XHR_CANCEL,
  FORECASTS_XHR_LOAD,
  FORECASTS_SELECTION_CHANGED,
} from '../actions/forecasts';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  status: 'loading',
  cancel: null,

  current: null,
  runs: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrSend(state, action) {
  return Object.assign({}, state, {
    status: 'loading',
    cancel: action.payload.cancel,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrError(state, action) {
  return Object.assign({}, state, {
    status: 'failed',
    cancel: null,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrCancel(state, action) {
  return Object.assign({}, state, {
    status: 'canceled',
    cancel: null,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrLoad(state, action) {
  let runs = (action.payload.data.runs || [])
    .map((run) => {
      return moment.utc(run, 'YYYY-MM-DD HH:mm:ss').valueOf();
    })
    .sort((a, b) => b - a);

  return Object.assign({}, state, {
    status: 'ready',
    cancel: null,
    current: includes(runs, state.current) ? state.current : runs[0],
    runs,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleSelectionChange(state, action) {
  return Object.assign({}, state, {
    current: action.payload.current,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function forecastsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FORECASTS_XHR_SEND:
      return handleXhrSend(state, action);

    case FORECASTS_XHR_ERROR:
      return handleXhrError(state, action);

    case FORECASTS_XHR_CANCEL:
      return handleXhrCancel(state, action);

    case FORECASTS_XHR_LOAD:
      return handleXhrLoad(state, action);

    case FORECASTS_SELECTION_CHANGED:
      return handleSelectionChange(state, action);

    default:
      return state;
  }
}
