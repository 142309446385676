/**
 * @module react/containers/forecasts
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import ForecastsFetch from './fetch';
import ForecastsList from './list';
import {
  issueForecastsRequest,
  cancelForecastsRequest,
} from '../../redux/actions/forecasts';

import loading from '../../img/fetch/load.gif';
import error from '../../img/fetch/error.svg';
import abort from '../../img/fetch/abort.svg';

/**
 *
 */
class Forecasts extends Component {
  /**
   *
   */

  constructor(props) {
    super(props);

    this.infotexts = {
      loading: {
        icon: loading,
        title: 'Loading',
        message: 'Fetching the forecasts from the server.',
        action: {
          label: 'Abort',
          onClick: this.props.cancelFetch,
        },
      },
      failed: {
        icon: error,
        title: 'Error',
        message: 'Failed to fetch the forecasts from the server.',
        action: {
          label: 'Retry',
          onClick: this.props.issueFetch,
        },
      },
      canceled: {
        icon: abort,
        title: 'Aborted',
        message: 'Fetching has been canceled.',
        action: {
          label: 'Retry',
          onClick: this.props.issueFetch,
        },
      },
    };
  }

  //   ____                 _
  // |  _ \ ___  __ _  ___| |_
  // | |_) / _ \/ _` |/ __| __|
  // |  _ <  __/ (_| | (__| |_
  // |_| \_\___|\__,_|\___|\__|
  //

  /**
   *
   */
  componentDidMount() {
    this.props.issueFetch();
    // fetch forecasts every 10 minutes
    this.interval = setInterval(() => this.props.issueFetch(), 10 * 60 * 1000);
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.cancelFetch();
    clearInterval(this.interval);
  }

  /**
   *
   */
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.status !== nextProps.status ||
      (this.props.status === 'ready' &&
        this.props.current !== nextProps.current) ||
      !isEqual(this.props.runs, nextProps.runs)
    );
  }

  /**
   *
   */
  render() {
    switch (this.props.status) {
      case 'loading':
      case 'failed':
      case 'canceled':
        return <ForecastsFetch info={this.infotexts[this.props.status]} />;

      case 'ready':
        return (
          <div className="forecasts">
            <div className="forecasts-list">
              <ForecastsList
                runs={this.props.runs}
                current={this.props.current}
              />
            </div>

            <div className="forecasts-controls">
              <strong className="controls-label">Forecasts</strong>
              <button
                className="controls-reload"
                type="button"
                onClick={this.props.onResetClick}
              >
                Reload
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
}

/**
 *
 */
function mapStateToProps(state, ownProps) {
  return {
    runs: state.forecasts.runs,
    current: state.forecasts.current,
    status: state.forecasts.status,
  };
}

/**
 *
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    onResetClick: () => dispatch(issueForecastsRequest()),
    issueFetch: () => dispatch(issueForecastsRequest()),
    cancelFetch: () => dispatch(cancelForecastsRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forecasts);
