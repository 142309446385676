/**
 * @module redux/actions/cloud
 */

export const CLOUD_RESET = 'CLOUD_RESET';
export const CLOUD_XHR_DISPATCH = 'CLOUD_XHR_DISPATCH';
export const CLOUD_XHR_CANCEL = 'CLOUD_XHR_CANCEL';
export const CLOUD_XHR_ERROR = 'CLOUD_XHR_ERROR';
export const CLOUD_XHR_SUCCESS = 'CLOUD_XHR_SUCCESS';

/**
 * Restores the initial state.
 * @returns {FluxStandardAction}
 */
export function resetCloud() {
  return { type: CLOUD_RESET };
}

/**
 * @param {number} init
 * @param {number} time
 * @param {object[]} latlngs
 * @returns {FluxStandardAction}
 */
export function dispatchCloudRequest(init, time, latlngs) {
  return {
    type: CLOUD_XHR_DISPATCH,
    payload: { init, time, latlngs },
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function cancelCloudRequest(error) {
  return {
    type: CLOUD_XHR_CANCEL,
    payload: error,
    error: true,
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function cloudRequestFailed(error) {
  return {
    type: CLOUD_XHR_ERROR,
    payload: error,
    error: true,
  };
}

/**
 * @param {object} response
 * @returns {FluxStandardAction}
 */
export function cloudRequestSuccessful(response) {
  return {
    type: CLOUD_XHR_SUCCESS,
    payload: response,
  };
}
