/**
 * @module redux/reducers/presets
 */

import {
  PRESETS_XHR_LOAD,
  PRESETS_XHR_SEND,
  PRESETS_XHR_ERROR,
  PRESETS_XHR_CANCEL,
} from '../actions/presets';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  status: 'loading',
  cancel: null,
  waypoints: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrSend(state, action) {
  return Object.assign({}, state, {
    status: 'loading',
    cancel: action.payload.cancel,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrError(state, action) {
  return Object.assign({}, state, {
    status: 'failed',
    cancel: null,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrCancel(state, action) {
  return Object.assign({}, state, {
    status: 'canceled',
    cancel: null,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrLoad(state, action) {
  return Object.assign({}, state, {
    status: 'ready',
    cancel: null,
    waypoints: action.payload.data,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function presetsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRESETS_XHR_SEND:
      return handleXhrSend(state, action);

    case PRESETS_XHR_ERROR:
      return handleXhrError(state, action);

    case PRESETS_XHR_CANCEL:
      return handleXhrCancel(state, action);

    case PRESETS_XHR_LOAD:
      return handleXhrLoad(state, action);

    default:
      return state;
  }
}
