/**
 * @module react/containers/widget
 */

import React, { Component } from 'react';
import L from 'leaflet';
import { widget } from 'leaflet-widget';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import moment from 'moment';
import { connect } from 'react-redux';

import * as controls from './controls';
import * as layers from './layers';

import {
  updateStartStopTime,
  updateCurrentTime,
  updateNowTime,
  updateAltitude,
  updateView,
} from '../../redux/actions/widget';
import {
  createWaypoint,
  showWaypointForm,
} from '../../redux/actions/flightpath';

import { ALTITUDES, KEYCODES } from '../../config/config-base';

/**
 * @extends {React.Component}
 */
class Widget extends Component {
  /**
   * @see {@link https://reactjs.org/docs/react-component.html#constructor}
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.onKeyUpHandler = this.onKeyUp.bind(this);
  }

  /**
   * @param {KeyboardEvent} event
   * @return {void}
   */
  onKeyUp(event) {
    if (this.props.waypoint === null) {
      return;
    }

    let forecast, step, newTime;
    let layer, name, index;

    switch (event.keyCode) {
      case KEYCODES.ArrowLeft:
        forecast = +(
          this.widget.time.options.currentTime > this.widget.time._nowTime
        );
        step = this.widget.time.options.tickStep[forecast];

        newTime = moment.utc(this.widget.time.options.currentTime);
        newTime.subtract(step[0], step[1]);

        this.widget.time.setTime(newTime);
        break;

      case KEYCODES.ArrowRight:
        forecast = +(
          this.widget.time.options.currentTime > this.widget.time._nowTime
        );
        step = this.widget.time.options.tickStep[forecast];

        newTime = moment.utc(this.widget.time.options.currentTime);
        newTime.add(step[0], step[1]);

        this.widget.time.setTime(newTime);
        break;

      case KEYCODES.ArrowUp:
        layer = this.widget.layers
          .getLayersByGroup('icing')
          .filter((layer) => layer._map)[0];
        name = +layer.options.name.substring(6);
        index = ALTITUDES.indexOf(name) - 1;

        if (index >= 0) {
          this.props.updateAltitude(ALTITUDES[index]);
        }
        break;

      case KEYCODES.ArrowDown:
        layer = this.widget.layers
          .getLayersByGroup('icing')
          .filter((layer) => layer._map)[0];
        name = +layer.options.name.substring(6);
        index = ALTITUDES.indexOf(name) + 1;

        if (index < ALTITUDES.length) {
          this.props.updateAltitude(ALTITUDES[index]);
        }
        break;
      default:
        break;
    }
  }

  //   ____                 _
  // |  _ \ ___  __ _  ___| |_
  // | |_) / _ \/ _` |/ __| __|
  // |  _ <  __/ (_| | (__| |_
  // |_| \_\___|\__,_|\___|\__|
  //

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidmount}
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUpHandler);

    this.widget = widget({
      parentNode: this.container,
      time: {
        startTime: this.props.startTime,
        stopTime: this.props.stopTime,
        currentTime: this.props.currentTime,
        tickStep: [
          [1, 'h'],
          [1, 'h'],
        ],
        tickDelay: 1000,
        tickModifier: (timestamp) =>
          moment.utc(timestamp).startOf('hour').valueOf(),
        shouldLoop: true,
      },
      map: {
        center: this.props.center,
        zoom: this.props.zoom,
        zoomDelta: 0.5,
        zoomSnap: 0.5,
        minZoom: 7,
        wheelPxPerZoomLevel: 100,
        doubleClickZoom: false,
        // Keyboard events are used to manipulate time and altitude.
        // See this.onKeyUp() for the implementation.
        keyboard: false,
      },
    });

    let control_attribution = L.control.attribution({
      name: 'attribution',
      position: 'topright',
    });

    control_attribution.addAttribution(
      '© <a href="https://meteotest.ch/" target="_blank">Meteotest</a>'
    );
    this.widget.controls.addControl(control_attribution);

    window.w = this.widget;

    this.widget.map._map.on('click', (event) => {
      this.props.addWaypoint({
        lat: event.latlng.lat.toFixed(6),
        lng: event.latlng.lng.toFixed(6),
      });
    });

    this.widget.layers.on('LAYERS_LAYER_SHOWN', (event) => {
      if (event.layer.options.group === 'icing') {
        this.props.updateAltitude(event.layer.options.name.substring(6));
      }
    });

    // Update the widget start and stop time to match the selected forecast run.
    this.widget.layers.on('LAYER_META_EXTRACTED', (event) => {
      let time_layer = event.layer.getTimeline();
      let time_widget = this.widget.time.options;

      if (!time_layer.startTime || !time_layer.stopTime) {
        return;
      }

      time_widget.startTime = time_layer.startTime;
      time_widget.stopTime = time_layer.stopTime;

      time_widget.currentTime = Math.min(
        time_widget.currentTime,
        time_widget.stopTime
      );
      time_widget.currentTime = Math.max(
        time_widget.currentTime,
        time_widget.startTime
      );

      this.widget.time.setTime(time_widget.currentTime);

      this.props.updateStartStopTime(
        time_widget.startTime,
        time_widget.stopTime
      );

      controls.timeslider()._update();
    });

    this.widget.time.on('TIME_NOW_UPDATED', (event) => {
      this.props.updateNowTime(event.now);
    });

    // Save the current time to the Redux state so it can be used to reinitialize the app from localStorage.
    this.widget.time.on(
      'TIME_PLAYBACK_TICKED TIME_PLAYBACK_SEEKED',
      (event) => {
        this.props.updateCurrentTime(event.current);
      }
    );

    // Save the current zoom and center to the Redux state so it can be used to reinitialize the app from localStorage.
    this.widget.map._map.on(
      'zoomend moveend',
      debounce((event) => {
        let { lat, lng } = this.widget.map._map.getCenter();
        let zoom = this.widget.map._map.getZoom();
        this.props.updateView([lat, lng], zoom);
      }, 300)
    );

    this.widget.layers.addLayer(layers.background());
    this.widget.layers.addLayer(layers.foreground());

    // Add the layer with the flightpath.
    this.widget.layers.addLayer(layers.flightpath(this.props.showForm));
    this.widget.layers
      .getLayer('flightpath')
      .setWaypoints(this.props.flightpath);

    // Preset waypoints as markers
    this.widget.layers.addLayer(layers.presets(this.props.addWaypoint));
    this.widget.layers.getLayer('presets').setData(this.props.presets);

    // Icing GridAPI layers
    ALTITUDES.forEach((altitude) => {
      let when = moment.utc(this.props.forecast);

      let layer_icing = layers.icing(altitude, when);
      this.widget.layers.addLayer(layer_icing);

      let layer_cloud = layers.cloud(altitude, when);
      this.widget.layers.addLayer(layer_cloud);
    });

    //		this.widget.controls.addControl(controls.fullscreen());
    this.widget.controls.addControl(controls.icinglegend());
    this.widget.controls.addControl(controls.presetslegend());
    this.widget.controls.addControl(controls.playback(this.widget));
    this.widget.controls.addControl(controls.timeslider(this.widget));
    this.widget.controls.addControl(controls.layerslider(this.widget));

    this.widget.layers.showLayer('background');
    this.widget.layers.showLayer('foreground');
    this.widget.layers.showLayer('flightpath');
    this.widget.layers.showLayer('presets');
    this.widget.layers.showLayer('icing_' + this.props.altitude);
    this.widget.layers.showLayer('cloud_' + this.props.altitude);

    this.widget.time.setTime(this.widget.time.options.currentTime);
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentwillunmount}
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUpHandler);

    this.widget.layers.off('LAYERS_LAYER_SHOWN LAYER_META_EXTRACTED');
    this.widget.time.off('TIME_PLAYBACK_TICKED TIME_PLAYBACK_SEEKED');
    this.widget.map._map.off('zoomend moveend click');

    this.widget.destroy();

    delete window.w;
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidupdate}
   * @return {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !L.latLng(this.props.center).equals(this.widget.map._map.getCenter()) ||
      !isEqual(this.props.zoom, this.widget.map._map.getZoom())
    ) {
      this.widget.map._map.setView(this.props.center, this.props.view);
    }

    if (!isEqual(this.props.presets, prevProps.presets)) {
      this.widget.layers.getLayer('presets').setData(this.props.presets);
    }

    if (!isEqual(this.props.flightpath, prevProps.flightpath)) {
      this.widget.layers
        .getLayer('flightpath')
        .setWaypoints(this.props.flightpath);
    }

    if (this.props.forecast !== prevProps.forecast) {
      this.widget.layers.getLayersByGroup('icing').forEach((layer) => {
        layer.options.init = moment.utc(this.props.forecast);

        if (layer._map) {
          layer._fetchData();
        }
      });
      this.widget.layers.getLayersByGroup('cloud').forEach((layer) => {
        layer.options.init = moment.utc(this.props.forecast);

        if (layer._map) {
          layer._fetchData();
        }
      });
    }

    if (this.props.altitude !== prevProps.altitude) {
      this.widget.layers.showLayer('icing_' + this.props.altitude);
      this.widget.layers.showLayer('cloud_' + this.props.altitude);
    }

    if (this.props.currentTime !== prevProps.currentTime) {
      this.widget.time.setTime(this.props.currentTime);
    }
  }

  /**
   * @see {@link https://reactjs.org/docs/react-component.html#render}
   */
  render() {
    return <div ref={(node) => (this.container = node)} className="map"></div>;
  }
}

/**
 *
 */
function mapStateToProps(state, ownProps) {
  return {
    currentTime: state.widget.currentTime,
    startTime: state.widget.startTime,
    stopTime: state.widget.stopTime,
    center: state.widget.center,
    zoom: state.widget.zoom,
    altitude: state.widget.altitude,
    presets: state.presets.waypoints,
    forecast: state.forecasts.current,
    flightpath: state.flightpath.waypoints,
  };
}

/**
 *
 */
function mapDispatchToProps(dispatch, ownProps) {
  return {
    updateStartStopTime: (start, stop) =>
      dispatch(updateStartStopTime(start, stop)),
    updateCurrentTime: (current) => dispatch(updateCurrentTime(current)),
    updateAltitude: (altitude) => dispatch(updateAltitude(altitude)),
    updateView: (center, zoom) => dispatch(updateView(center, zoom)),
    showForm: (waypoint) => dispatch(showWaypointForm(waypoint)),
    addWaypoint: (waypoint) => dispatch(createWaypoint(waypoint)),
    updateNowTime: (now) => dispatch(updateNowTime(now)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
