/**
 * @module react/components/modal/form-item
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 */
export default function FormItem(props) {
  let { label, hint, touched, valid, error } = props;

  let child = React.Children.only(props.children);

  return (
    <div className={'form-item' + (touched && !valid ? ' form--error' : '')}>
      {touched && error && <p className="form-item-error">{error}</p>}

      <div className="form-item-wrapper">
        <label className="form-label" htmlFor={'form-' + child.props.name}>
          {label}
        </label>

        {child}
      </div>

      {hint && <p className="form-item-hint">{hint}</p>}
    </div>
  );
}

/**
 *
 */
FormItem.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,

  valid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool.isRequired,
};
