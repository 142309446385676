/**
 * @module redux/actions/presets
 */
import axios from 'axios';
import normalize from '../../utility/normalize';

export const PRESETS_XHR_LOAD = 'PRESETS_XHR_LOAD';
export const PRESETS_XHR_SEND = 'PRESETS_XHR_SEND';
export const PRESETS_XHR_ERROR = 'PRESETS_XHR_ERROR';
export const PRESETS_XHR_CANCEL = 'PRESETS_XHR_CANCEL';

const API = axios.create({
  baseURL: '/',
});

/**
 * @param {object} data
 * @returns {FluxStandardAction}
 */
export function presetsRequestLoaded(data) {
  return {
    type: PRESETS_XHR_LOAD,
    payload: { data },
  };
}

/**
 * @returns {FluxStandardAction}
 */
export function presetsRequestFailed() {
  return { type: PRESETS_XHR_ERROR };
}

/**
 * @returns {FluxStandardAction}
 */
export function presetsRequestAborted() {
  return { type: PRESETS_XHR_CANCEL };
}

/**
 * @param {function} cancel Axios cancel token.
 * @returns {FluxStandardAction}
 */
export function presetsRequestIssued(cancel) {
  return {
    type: PRESETS_XHR_SEND,
    payload: { cancel },
  };
}

/**
 *
 */
export function issuePresetsRequest() {
  return function (dispatch, getState) {
    dispatch(cancelPresetsRequest());

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let request_options = { cancelToken: source.token };

    API.get('presets.json', request_options)
      .then((response) => {
        dispatch(presetsRequestLoaded(normalize(response.data)));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          dispatch(presetsRequestAborted(error));
        } else {
          dispatch(presetsRequestFailed(error));
        }
      });
    dispatch(presetsRequestIssued(source.cancel));
  };
}

/**
 * @param {string} message
 */
export function cancelPresetsRequest(message) {
  return function (dispatch, getState) {
    let { cancel } = getState().presets;

    if (typeof cancel === 'function') {
      cancel(message);

      dispatch(presetsRequestAborted());
    }
  };
}
