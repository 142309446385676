/**
 * @module react/components/modal/form-textinput
 */
import React from 'react';

/**
 *
 */
export default function FormTextinput(props) {
  return (
    <input
      type="text"
      className="form-textinput"
      id={'form-' + props.name}
      name={props.name}
      value={props.value}
      disabled={props.disabled || false}
      onChange={props.onChange}
    />
  );
}
