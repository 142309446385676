/**
 * @module react/components/crosssection/crosssection-intro
 */
import React from 'react';

/**
 * The introduction screen, displayed when less than two waypoints are selected.
 * @param {object} props
 */
export default function CrosssectionIntro(props) {
  return (
    <div className="crosssection-intro">
      <p className="crosssection-intro-text">
        Tap onto the map for creating a flightpath or
        <br />
        Click «add» to create a custom (or predefined) waypoint
      </p>

      <ul className="crosssection-intro-links">
        <li className="crosssection-intro-links-meteotest">
          <a href="https://meteotest.ch/" target="_blank" rel="noreferrer">
            Meteotest
          </a>
        </li>
        <li className="crosssection-intro-links-rega">
          <a href="http://rega.ch/" target="_blank" rel="noreferrer">
            Rega
          </a>
        </li>
        <li className="crosssection-intro-links-airforce">
          <a href="http://www.luftwaffe.ch/" target="_blank" rel="noreferrer">
            Luftwaffe
          </a>
        </li>
      </ul>
    </div>
  );
}
