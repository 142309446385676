/**
 * @module redux/actions/topography
 */

export const TOPOGRAPHY_RESET = 'TOPOGRAPHY_RESET';
export const TOPOGRAPHY_XHR_DISPATCH = 'TOPOGRAPHY_XHR_DISPATCH';
export const TOPOGRAPHY_XHR_CANCEL = 'TOPOGRAPHY_XHR_CANCEL';
export const TOPOGRAPHY_XHR_ERROR = 'TOPOGRAPHY_XHR_ERROR';
export const TOPOGRAPHY_XHR_SUCCESS = 'TOPOGRAPHY_XHR_SUCCESS';

/**
 * Restores the initial state.
 * @returns {FluxStandardAction}
 */
export function resetTopography() {
  return { type: TOPOGRAPHY_RESET };
}

/**
 * @param {number} init
 * @param {number} time
 * @param {object[]} latlngs
 * @returns {FluxStandardAction}
 */
export function dispatchTopographyRequest(latlngs) {
  return {
    type: TOPOGRAPHY_XHR_DISPATCH,
    payload: { latlngs },
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function cancelTopographyRequest(error) {
  return {
    type: TOPOGRAPHY_XHR_CANCEL,
    payload: error,
    error: true,
  };
}

/**
 * @param {Error} error
 * @returns {FluxStandardAction}
 */
export function topographyRequestFailed(error) {
  return {
    type: TOPOGRAPHY_XHR_ERROR,
    payload: error,
    error: true,
  };
}

/**
 * @param {object} response
 * @returns {FluxStandardAction}
 */
export function topographyRequestSuccessful(response) {
  return {
    type: TOPOGRAPHY_XHR_SUCCESS,
    payload: response,
  };
}
