/**
 * @module redux/actions/flightpath
 */
import findIndex from 'lodash/findIndex';

export const FLIGHTPATH_WAYPOINT_CREATE = 'FLIGHTPATH_WAYPOINT_CREATE';
export const FLIGHTPATH_WAYPOINT_UPDATE = 'FLIGHTPATH_WAYPOINT_UPDATE';
export const FLIGHTPATH_WAYPOINT_DELETE = 'FLIGHTPATH_WAYPOINT_DELETE';
export const FLIGHTPATH_WAYPOINT_CLEAR = 'FLIGHTPATH_WAYPOINT_CLEAR';
export const FLIGHTPATH_FORM_SHOW = 'FLIGHTPATH_FORM_SHOW';
export const FLIGHTPATH_FORM_HIDE = 'FLIGHTPATH_FORM_HIDE';

/**
 *
 */
export function showHelp() {
  return function (dispatch, getState) {
    window.open('/docs', '_blank');
  };
}

/**
 * @param {object} waypoint
 * @returns {FluxStandardAction}
 */
export function createWaypoint(waypoint) {
  return {
    type: FLIGHTPATH_WAYPOINT_CREATE,
    payload: { waypoint },
  };
}

/**
 * @param {object} waypoint
 * @returns {FluxStandardAction}
 */
export function updateWaypoint(waypoint) {
  return {
    type: FLIGHTPATH_WAYPOINT_UPDATE,
    payload: { waypoint },
  };
}

/**
 * @param {object} waypoint
 * @returns {FluxStandardAction}
 */
export function deleteWaypoint(waypoint) {
  return {
    type: FLIGHTPATH_WAYPOINT_DELETE,
    payload: { waypoint },
  };
}

/**
 * @returns {FluxStandardAction}
 */
export function clearWaypoints() {
  return { type: FLIGHTPATH_WAYPOINT_CLEAR };
}

/**
 * @param {object} waypoint
 * @returns {FluxStandardAction}
 */
export function showWaypointForm(waypoint) {
  return {
    type: FLIGHTPATH_FORM_SHOW,
    payload: { waypoint },
  };
}

/**
 * @returns {FluxStandardAction}
 */
export function hideWaypointForm() {
  return { type: FLIGHTPATH_FORM_HIDE };
}

/**
 * @param {object} data
 */
export function saveWaypointForm(data) {
  return function (dispatch, getState) {
    let { presets } = getState();
    let waypoint;
    let preset = null;

    if (data.base !== '') {
      preset = data.base;
    } else if (data.hospital !== '') {
      preset = data.hospital;
    } else if (data.route !== '') {
      preset = data.route;
    }

    if (preset) {
      let index = findIndex(presets.waypoints, ['id', parseInt(preset, 10)]);

      waypoint = {
        name: presets.waypoints[index].name,
        lat: presets.waypoints[index].lat,
        lng: presets.waypoints[index].lng,
      };
    } else {
      waypoint = {
        name: null,
        lat: data.lat,
        lng: data.lng,
      };
    }

    waypoint.preset = preset;
    waypoint.index = parseInt(data.index, 10);

    if (data.id) {
      waypoint.id = parseInt(data.id, 10);
      dispatch(updateWaypoint(waypoint));
    } else {
      dispatch(createWaypoint(waypoint));
    }
  };
}
