// Fetch is used by the GridAPI Leaflet layer.
// import "whatwg-fetch";

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogicMiddleware } from 'redux-logic';
import { debounce } from 'lodash';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ajax } from 'rxjs/ajax';

import reducers from './redux/reducers';
import logics from './redux/logics';
// import App from "./react/containers/app";
import App from './App';
import { saveState, loadState } from './utility/persistentstate';

let reset = localStorage.getItem('reset');

// Update the following timestamp to Date.now() if you change the
// structure of the Redux store. Don't overuse this 'feature'
// as it will clear the cache for everyone.
if (!reset || reset < 1513177723789) {
  localStorage.setItem('state', undefined);
  localStorage.setItem('reset', Date.now());
}

let enhancer = applyMiddleware(createLogicMiddleware(logics, { ajax }), thunk);
// Don't attach the development tools in production to improve performance.
if (process.env.NODE_ENV !== 'production') {
  enhancer = composeWithDevTools(enhancer);
}

let store = createStore(reducers, loadState(), enhancer);
let node = document.getElementById('root');

// Sync the Redux store with localStorage after every change.
// Debounce the sync to save performance (and avoid race conditions).
store.subscribe(debounce(() => saveState(store.getState()), 300));

if (node) {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    node
  );
}
