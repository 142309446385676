/**
 * @module redux/reducers/icing
 */

import moment from 'moment';
import { merge, transpose } from 'd3-array';
import queryString from 'query-string';

import {
  ICING_RESET,
  ICING_XHR_DISPATCH,
  ICING_XHR_CANCEL,
  ICING_XHR_ERROR,
  ICING_XHR_SUCCESS,
} from '../actions/icing';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  status: 'empty',
  lastFetch: 0,

  scalarfields: {},

  total_distance: null,
  distances: [],
  altitudes: [],
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrDispatch(state, action) {
  return Object.assign({}, state, {
    status: 'loading',
    lastFetch: moment.utc().valueOf(),
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrCancel(state, action) {
  return Object.assign({}, state, {
    status: 'canceled',
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrError(state, action) {
  return Object.assign({}, state, {
    status: 'failed',
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function handleXhrSuccess(state, action) {
  let format = 'YYYY-MM-DD HH:mm:ss';

  let query = queryString.parse(action.payload.request.url.split('?')[1]);

  let samples = action.payload.response.samples;
  let total_distance = action.payload.response._metadata.total_distance;

  let scalarfield = merge(transpose(samples.map((sample) => sample.values)));

  let distances = samples.map((sample) => Math.round(sample.dist));
  let altitudes = query.params.match(/\d+(\.\d+)?/g).map(Math.round);

  let time = moment.utc(query.time, format).valueOf();
  let init = moment.utc(query.init, format).valueOf();
  let index = init + '.' + time;

  let scalarfields = Object.assign({}, state.scalarfields, {
    [index]: scalarfield,
  });

  return Object.assign({}, state, {
    status: 'ready',

    scalarfields,

    total_distance,
    distances,
    altitudes,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function icingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ICING_RESET:
      return Object.assign({}, INITIAL_STATE);

    case ICING_XHR_DISPATCH:
      return handleXhrDispatch(state, action);

    case ICING_XHR_CANCEL:
      return handleXhrCancel(state, action);

    case ICING_XHR_ERROR:
      return handleXhrError(state, action);

    case ICING_XHR_SUCCESS:
      return handleXhrSuccess(state, action);

    default:
      return state;
  }
}
