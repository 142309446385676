/**
 * @module convert
 * @see {@link http://www.meters-to-feet.com/}
 */

/**
 * @param {number} meters The value in meters to convert.
 * @returns {number} The input value converted to feet.
 */
export function metersToFeet(meters) {
  return meters * 3.280839895;
}

/**
 * @param {number} feet The value in feet to convert.
 * @returns {number} The input value converted to meters.
 */
export function feetToMeters(feet) {
  return feet * 0.3048;
}
