/**
 * @module redux/reducers/flightpath
 */
import clone from 'lodash/clone';
import findIndex from 'lodash/findIndex';

import {
  FLIGHTPATH_WAYPOINT_CREATE,
  FLIGHTPATH_WAYPOINT_UPDATE,
  FLIGHTPATH_WAYPOINT_DELETE,
  FLIGHTPATH_WAYPOINT_CLEAR,
  FLIGHTPATH_FORM_SHOW,
  FLIGHTPATH_FORM_HIDE,
} from '../actions/flightpath';

/**
 * @type {object}
 */
const INITIAL_STATE = {
  waypoints: [],
  edit: null,
  nextId: 100000,
};

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function createWaypoint(state, action) {
  let waypoints = state.waypoints.slice();

  let waypoint = action.payload.waypoint;
  waypoint.id = state.nextId;
  waypoint.index = waypoints.length;

  waypoints.push(waypoint);

  return Object.assign({}, state, {
    waypoints,
    edit: null,
    nextId: state.nextId + 1,
  });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function updateWaypoint(state, action) {
  let waypoint = action.payload.waypoint;
  let index = findIndex(state.waypoints, ['id', waypoint.id]);

  if (index >= 0) {
    let waypoints = clone(state.waypoints);
    waypoints.splice(index, 1, waypoint);

    return Object.assign({}, state, {
      waypoints,
      edit: null,
    });
  } else {
    return state;
  }
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function deleteWaypoint(state, action) {
  let waypoint = action.payload.waypoint;

  let index;
  if (isNaN(waypoint.index)) {
    index = findIndex(state.waypoints, ['id', waypoint.id]);
  } else {
    index = waypoint.index;
  }

  if (index >= 0) {
    let waypoints = clone(state.waypoints);
    waypoints.splice(index, 1);

    for (let i = index; i < waypoints.length; i++) {
      waypoints[i].index -= 1;
    }

    return Object.assign({}, state, {
      waypoints,
      edit: null,
    });
  } else {
    return state;
  }
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function clearWaypoints(state, action) {
  return Object.assign({}, state, { waypoints: [] });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function showForm(state, action) {
  return Object.assign({}, state, { edit: action.payload.waypoint || {} });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function hideForm(state, action) {
  return Object.assign({}, state, { edit: null });
}

/**
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
export default function flightpathReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FLIGHTPATH_WAYPOINT_CREATE:
      return createWaypoint(state, action);

    case FLIGHTPATH_WAYPOINT_UPDATE:
      return updateWaypoint(state, action);

    case FLIGHTPATH_WAYPOINT_DELETE:
      return deleteWaypoint(state, action);

    case FLIGHTPATH_WAYPOINT_CLEAR:
      return clearWaypoints(state, action);

    case FLIGHTPATH_FORM_SHOW:
      return showForm(state, action);

    case FLIGHTPATH_FORM_HIDE:
      return hideForm(state, action);

    default:
      return state;
  }
}
